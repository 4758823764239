import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {BASE_URL,BACKENDTOKEN} from '../../../../Constant/Constant';
import { toast } from 'react-toastify';

const BookingBox = () => {
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        TaxibookingTitle: '',
        RentalCarTitle: '',
        PickupLocationTitle: '',
        DropLocationTitle: '',
        Request_now_Button: '',
        Schedule_later_Button: ''
    });
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDataExists, setIsDataExists] = useState(false);
    const [languageId, setLanguageId] = useState(localStorage.getItem('selectedLanguageId')); // Fetch language_id from localStorage

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}staticcontent/get?language_id=${languageId}`,{
                    headers:{
                        'Authorization': `Bearer ${BACKENDTOKEN}`
                    }
                });

                if (response.data && response.data.bookingbox) {
                    const parsedData = JSON.parse(response.data.bookingbox); 
                    setFormValues(parsedData); 
                    setIsDataExists(true);
                    
                } else {
                    setIsDataExists(false);
                }
            } catch (error) {
                setIsEditMode(true);
            }
           
        };

        fetchData();
    }, [languageId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const payload = {
                bookingbox: formValues,
                language_id: languageId
            };

            const url = `${BASE_URL}staticcontent/bookingbox`;

            const response = await axios.post(url, payload,{
                headers:{
                    'Authorization': `Bearer ${BACKENDTOKEN}`
                }
            });

            if (response.data.message === 'bookingbox Process successfully!') {
                setIsDataExists(true);
                toast.dismiss()
                toast.success('Data Process Successfully', {
                    autoClose: 1500,
                });
            } else {
                toast.dismiss()
                toast.error('Unexpected response from the server.', {
                    autoClose: 1500,
                });
            }

            setIsEditMode(false);
        } catch (error) {
            toast.dismiss()
            toast.error('Error saving data.', {
                autoClose: 1500,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <form className="row g-3" onSubmit={handleSubmit}>
         
            {/* Form Fields */}
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Taxibooking Title</label>
                    <input
                        name="TaxibookingTitle"
                        type="text"
                        className="form-control"
                        value={formValues.TaxibookingTitle}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Date Title</label>
                    <input
                        name="RentalCarTitle"
                        type="text"
                        className="form-control"
                        value={formValues.RentalCarTitle}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Time Title</label>
                    <input
                        name="Request_now_Button"
                        type="text"
                        className="form-control"
                        value={formValues.Request_now_Button}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">PickupLocation Title</label>
                    <input
                        name="PickupLocationTitle"
                        type="text"
                        className="form-control"
                        value={formValues.PickupLocationTitle}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">DropLocation Title</label>
                    <input
                        name="DropLocationTitle"
                        type="text"
                        className="form-control"
                        value={formValues.DropLocationTitle}
                        onChange={handleChange}
                    />
                </div>
            </div>
            {/* <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Request_now Button</label>
                    <input
                        name="Request_now_Button"
                        type="text"
                        className="form-control"
                        value={formValues.Request_now_Button}
                        onChange={handleChange}
                    />
                </div>
            </div> */}
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Submit Button Title</label>
                    <input
                        name="Schedule_later_Button"
                        type="text"
                        className="form-control"
                        value={formValues.Schedule_later_Button}
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className="card-footer text-end">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loading}
                    >
                        {loading ? 'Saving...' : (isDataExists ? 'Update' : 'Add')}
                    </button>
                </div>
        </form>
    );
};

export default BookingBox;
