
import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { BASE_URL, BACKENDTOKEN } from '../../../../Constant/Constant';
import { toast } from 'react-toastify';

const HowToWorks = () => {
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        MainTitle: '',
        Title1: '',
        Description1: '',
        Title2: '',
        Description2: '',
        Title3: '',
        Description3: '',
    });
    const [imageFiles, setImageFiles] = useState({
        Image1: null,
        Image2: null,
        Image3: null,
    });
    const [existingImages, setExistingImages] = useState({
        Image1: '',
        Image2: '',
        Image3: '',
    });
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDataExists, setIsDataExists] = useState(false);
    const [languageId, setLanguageId] = useState(localStorage.getItem('selectedLanguageId'));
    const [id, setId] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${BASE_URL}staticcontent/get?language_id=${languageId}`;
                const response = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${BACKENDTOKEN}`,
                    },
                });

                setId(response.data.id);
                if (response.data && response.data.howtowork) {
                    const parsedData = JSON.parse(response.data.howtowork);
                    setFormValues(parsedData);
                    setExistingImages({
                        Image1: parsedData.Image1 || '',
                        Image2:parsedData.Image2 || '',
                        Image3: parsedData.Image3 || '',
                    });
                    setIsDataExists(true);
                    setIsEditMode(false);
                } else {
                    setFormValues({
                        MainTitle: '',
                        Title1: '',
                        Description1: '',
                        Title2: '',
                        Description2: '',
                        Title3: '',
                        Description3: '',
                    });
                    setIsDataExists(false);
                    setIsEditMode(true);
                }
            } catch (error) {
                setIsEditMode(true);
            }
        };

        fetchData();
    }, [languageId, id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setImageFiles((prevFiles) => ({
            ...prevFiles,
            [name]: files[0],
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const formData = new FormData();
            formData.append('howtowork', JSON.stringify(formValues));
            formData.append('language_id', languageId);

            if (imageFiles.Image1) {
                formData.append('Image1', imageFiles.Image1);
            }
            if (imageFiles.Image2) {
                formData.append('Image2', imageFiles.Image2);
            }
            if (imageFiles.Image3) {
                formData.append('Image3', imageFiles.Image3);
            }

            const url = `${BASE_URL}staticcontent/howtowork`;
            const response = await axios.post(url, formData, {
                headers: {
                    Authorization: `Bearer ${BACKENDTOKEN}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data && response.data.message === 'howtowork Process successfully!') {
                toast.success('Data processed successfully!', {
                    autoClose: 1500,
                });
                setIsDataExists(true);
            } else {
                toast.warn('Unexpected response from the server.', {
                    autoClose: 1500,
                });
            }

            setIsEditMode(false);
        } catch (error) {
            toast.error('Error saving data.', {
                autoClose: 1500,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <form className="row g-3" onSubmit={handleSubmit}>
                <div className="col-md-4">
                    <label className="form-label">Main Title</label>
                    <input
                        name="MainTitle"
                        type="text"
                        className="form-control"
                        value={formValues.MainTitle}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>

                <div className="col-md-4">
                    <label className="form-label">Title1</label>
                    <input
                        name="Title1"
                        type="text"
                        className="form-control"
                        value={formValues.Title1}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
                <div className="col-md-4">
                    <label className="form-label"> Title2</label>
                    <input
                        name="Title2"
                        type="text"
                        className="form-control"
                        value={formValues.Title2}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
                <div className="col-md-4">
                    <label className="form-label">Title3</label>
                    <input
                        name="Title3"
                        type="text"
                        className="form-control"
                        value={formValues.Title3}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
                <div className="col-md-4">
                    <label className="form-label"> Description1</label>
                    <textarea
                        name="Description1"
                        className="form-control"
                        rows="2" // This will create a 2-row textarea
                        value={formValues.Description1}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
                <div className="col-md-4">
                    <label className="form-label"> Description2</label>
                    <textarea
                        name="Description2"
                        className="form-control"
                        rows="2" // This will create a 2-row textarea
                        value={formValues.Description2}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
                <div className="col-md-4">
                    <label className="form-label"> Description3</label>
                    <textarea
                        name="Description3"
                        className="form-control"
                        rows="2" // This will create a 2-row textarea
                        value={formValues.Description3}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>

                {/* Display existing images */}
                <div className="col-md-4">
                    <label className="form-label">Image 1</label>
                    <input
                        name="Image1"
                        type="file"
                        className="form-control"
                        onChange={handleFileChange}
                        disabled={loading}
                    />
                     {existingImages.Image1 && (
                        <img
                            src={`${BASE_URL}uploads/${existingImages.Image1}`}
                            alt="Existing Image 1"
                            style={{ width: '100px', height: 'auto', borderRadius: '5px', objectFit: 'cover' }} // Passport size style
                        />
                    )}
                </div>

                <div className="col-md-4">
                    <label className="form-label">Image 2</label>
                    {existingImages.Image2 && (
                        <img
                        src={`${BASE_URL}uploads/${existingImages.Image2}`}
                            alt="Existing Image 2"
                            style={{ width: '100px', height: 'auto', borderRadius: '5px', objectFit: 'cover' }}
                        />
                    )}
                    <input
                        name="Image2"
                        type="file"
                        className="form-control"
                        onChange={handleFileChange}
                        disabled={loading}
                    />
                </div>

                <div className="col-md-4">
                    <label className="form-label">Image 3</label>
                    {existingImages.Image3 && (
                        <img
                        src={`${BASE_URL}uploads/${existingImages.Image3}`}
                            alt="Existing Image 3"
                            style={{ width: '100px', height: 'auto', borderRadius: '5px', objectFit: 'cover' }}
                        />
                    )}
                    <input
                        name="Image3"
                        type="file"
                        className="form-control"
                        onChange={handleFileChange}
                        disabled={loading}
                    />
                </div>

                <div className="card-footer text-end mt-5">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loading}
                    >
                        {loading ? 'Saving...' : isDataExists ? 'Update' : 'Save'}
                    </button>
                </div>
            </form>
        </>
    );
};

export default HowToWorks;
