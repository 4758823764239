import React, { useState, useEffect, useRef } from 'react';
import { IoAddCircleOutline } from "react-icons/io5";
import { MdOutlineCancel } from 'react-icons/md';
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BASE_URL,BACKENDTOKEN} from '../../Constant/Constant'

const AddServices = () => {
    const [fields, setFields] = useState([{ id: 1, text: '' }]);
    const [loading, setLoading] = useState(false);
    const fileInputRef = useRef(null);
    const [languageId, setLanguageId] = useState('');
    const [formValues, setFormValues] = useState({
        title: '',
        preview_image: null,
        short_description: '',
        Image: null,
        service_category: '',
        points: [{ id: 1, text: '' }],
        description: ''
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const storedLanguageId = localStorage.getItem('selectedLanguageId');
        if (storedLanguageId) {
            setLanguageId(storedLanguageId);
        }
    }, []);

    useEffect(() => {
        setFormValues(prevValues => ({
            ...prevValues,
            language_id: languageId
        }));
    }, [languageId]);

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        if (type === 'file') {
            setFormValues(prevValues => ({
                ...prevValues,
                [name]: files[0]
            }));
        } else {
            setFormValues(prevValues => ({
                ...prevValues,
                [name]: value
            }));
        }
    };

    const handleQuillChange = (value) => {
        setFormValues(prevValues => ({
            ...prevValues,
            description: value
        }));
    };

    const handleAddField = () => {
        if (fields.length >= 6) {
            toast.dismiss();
            toast.warn('Maximum of 6 points allowed');
            return;
        }
        const newId = fields.length ? fields[fields.length - 1].id + 1 : 1;
        const newField = { id: newId, text: '' };
        setFields(prevFields => [...prevFields, newField]);
        setFormValues(prevValues => ({
            ...prevValues,
            points: [...prevValues.points, newField]
        }));
    };

    const handleDeleteField = (id) => {
        if (fields.length <= 1) return; // Prevent removing the last field

        const updatedFields = fields.filter(field => field.id !== id);
        setFields(updatedFields);
        setFormValues(prevValues => ({
            ...prevValues,
            points: prevValues.points.filter(point => point.id !== id)
        }));
    };

    const validate = () => {
        const errors = {};
        if (!formValues.title) errors.title = 'Title is required';

        // Check if Image is provided
        if (!formValues.Image) {
            errors.Image = 'Image is required';
        } else if (formValues.Image && !['image/jpeg', 'image/png', 'image/gif'].includes(formValues.Image.type)) {
            errors.Image = 'Unsupported file type. Only JPEG, PNG, and GIF are allowed.';
        }

        // Check if short_description is provided
        if (!formValues.short_description) {
            errors.short_description = 'Short description is required';
        }

        // Check if preview_image is provided
        if (!formValues.preview_image) {
            errors.preview_image = 'Preview Image is required';
        } else if (formValues.preview_image && !['image/jpeg', 'image/png', 'image/gif'].includes(formValues.preview_image.type)) {
            errors.preview_image = 'Unsupported file type. Only JPEG, PNG, and GIF are allowed.';
        }
        if (!formValues.service_category) errors.service_category = 'Service category is required';
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!validate()) return;
    
        setLoading(true);
    
        const formData = new FormData();
        formData.append('title', formValues.title);
        formData.append('short_description', formValues.short_description);
        formData.append('service_category', formValues.service_category);
        formData.append('points', JSON.stringify(formValues.points.map(point => point.text)));
        if (formValues.Image) formData.append('Image', formValues.Image);
        if (formValues.preview_image) formData.append('preview_image', formValues.preview_image);
        formData.append('description', formValues.description);
        formData.append('language_id', formValues.language_id);
    
        try {
            const response = await axios.post(`${BASE_URL}service/add`, formData, {
                headers: {
                    'Authorization': `Bearer ${BACKENDTOKEN}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            const data = response.data; // Extract the backend response
    
            toast.dismiss(); // Clear all active toasts
            toast.success(data.message || 'Service added successfully', {
                autoClose: 1500,             
            });
    
            setFormValues({
                title: '',
                Image: null,
                short_description: '',
                preview_image: null,
                language_id: '',
                service_category: '',
                points: [{ id: 1, text: '' }],
                description: ''
            });
            setFields([{ id: 1, text: '' }]);
            if (fileInputRef.current) {
                fileInputRef.current.value = ''; // Clear the file input field using the ref
            }
    
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Error adding service';
            toast.dismiss(); // Clear all active toasts
            toast.error(errorMessage, {
                autoClose: 1500,             
            });
        } finally {
            setLoading(false);
        }
    };
    

 

    return (
        <div className="container-xl my-4">
  <ToastContainer />
            <div className="row mb-3 mt-1">
                <div className="col-md-6">
                    <h4><span className="text-muted fw-light"> </span> Services</h4>
                </div>
                <div className="col-md-6">
                    <div className="breadcumbtext">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-end">
                                <li className="breadcrumb-item">
                                    <Link className="footer-link fs-6" to="../serviceslist">Back</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link className="footer-link fs-6" to="#">Add</Link>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="row row-cards">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header" style={{ borderBottom: '1px solid #ccc', marginBottom: '2rem', paddingBottom: '1rem' }}>
                            <div className='d-flex justify-content-between align-items-center'>
                                <h5 className="card-title me-3">Add</h5>
                            </div>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit} className="row g-3">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Title</label>
                                        <input
                                            name="title"
                                            type="text"
                                            className={`form-control ${errors.title ? 'is-invalid' : ''}`}
                                            value={formValues.title}
                                            onChange={handleChange}
                                        />
                                        {errors.title && <div className="invalid-feedback">{errors.title}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Image</label>
                                        <input
                                            name="Image"
                                             accept="image/jpeg, image/png, image/gif"
                                            type="file"
                                            ref={fileInputRef}
                                            className={`form-control ${errors.Image ? 'is-invalid' : ''}`}
                                            onChange={handleChange}
                                        />
                                        {errors.Image && <div className="invalid-feedback">{errors.Image}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Service Category</label>
                                        <select
                                            name="service_category"
                                            className={`form-select ${errors.service_category ? 'is-invalid' : ''}`}
                                            value={formValues.service_category}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select</option>
                                            <option value="Business Class">Business Class</option>
                                            <option value="Chauffeur Hailing">Chauffeur Hailing</option>
                                            <option value="Airport Transfers">Airport Transfers</option>
                                            <option value="Sprinter Class">Sprinter Class</option>
                                            <option value="Wedding Class">Wedding Class</option>
                                            <option value="Travel Transfer">Travel Transfer</option>
                                        </select>
                                        {errors.service_category && <div className="invalid-feedback">{errors.service_category}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Points</label>
                                        <div>
                                            {formValues.points.map((point, index) => (
                                                <div key={point.id} className="input-group mb-3">
                                                    <input
                                                        className='form-control'
                                                        type="text"
                                                        value={point.text}
                                                        onChange={(e) => {
                                                            const newPoints = [...formValues.points];
                                                            newPoints[index].text = e.target.value;
                                                            setFormValues(prevValues => ({
                                                                ...prevValues,
                                                                points: newPoints
                                                            }));
                                                        }}
                                                    />
                                                    {index !== 0 && ( // Show delete button for all fields except the first one
                                                        <button
                                                            className="btn btn-label-secondary waves-effect waves-light"
                                                            type="button"
                                                            onClick={() => handleDeleteField(point.id)}
                                                        >
                                                            <MdOutlineCancel />
                                                        </button>
                                                    )}
                                                </div>
                                            ))}
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-sm"
                                                onClick={handleAddField}
                                            >
                                                <IoAddCircleOutline style={{ fontSize: "16px" }} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Short Description</label>
                                        <input
                                            name="short_description"
                                            type="text"
                                            className={`form-control ${errors.short_description ? 'is-invalid' : ''}`}
                                            value={formValues.short_description}
                                            onChange={handleChange}
                                        />
                                        {errors.short_description && <div className="invalid-feedback">{errors.short_description}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Preview Image</label>
                                        <input
                                            name="preview_image"
                                            type="file"
                                             accept="image/jpeg, image/png, image/gif"
                                            ref={fileInputRef}
                                            className={`form-control ${errors.preview_image ? 'is-invalid' : ''}`}
                                            onChange={handleChange}
                                        />
                                        {errors.preview_image && <div className="invalid-feedback">{errors.preview_image}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Description</label>
                                        <ReactQuill
                                            value={formValues.description}
                                            onChange={handleQuillChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 d-flex justify-content-end align-items-center">
                                    <button
                                        type="submit"
                                        className={`btn btn-primary ${loading ? 'disabled' : ''}`}
                                        disabled={loading}
                                    >
                                        {loading ? 'Adding...' : 'Add'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddServices;
