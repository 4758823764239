import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {BASE_URL,BACKENDTOKEN} from '../../../Constant/Constant'
import { toast } from 'react-toastify';

// Validation Schema
const DisplayingErrorMessagesSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    image: Yup.mixed().required('Car image is required').test('fileSize', 'File is too large', value => !value || (value && value.size <= 1024 * 1024 * 5)) // Max 5MB
});

const ModalCarCategory = ({ refreshData }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const initialValues = {
        name: '',
        image: null,
        language_id: localStorage.getItem('selectedLanguageId'),
    };

    const handleSubmit = async (values, { resetForm }) => {
        setLoading(true);

        try {
            const formData = new FormData();
            formData.append('name', values.name);
            formData.append('language_id', values.language_id);

            if (values.image && values.image instanceof File) {
                formData.append('image', values.image);
            }

            await axios.post(`${BASE_URL}carcategory/add`, formData, {
                headers: {
                'Authorization': `Bearer ${BACKENDTOKEN}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            setLoading(false);
            refreshData();
            setModalOpen(false);
            resetForm();
        } catch (error) {
            toast.dismiss();
        
            // Extract and display the backend message if it exists, otherwise show a generic error message
            const errorMessage = error.response?.data?.message || 'Error submitting form';
            toast.error(errorMessage, {
                autoClose: 1500,
            });
            setLoading(false);
         
        }
    };

    return (
        <>
            <Link type="button" className="btn btn-primary waves-effect waves-light" onClick={() => setModalOpen(true)}>
                <i className="fa fa-plus me-2"></i>Add 
            </Link>

            <div className={`modal fade ${modalOpen ? 'show' : ''}`} style={{ display: modalOpen ? 'block' : 'none' }} tabIndex="-1" aria-hidden={!modalOpen}>
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content" style={{ backgroundColor: 'white' }}>
                        <div className="modal-header">
                            <h5 className="modal-name" id="modalCentername">Add</h5>
                            <button type="button" className="btn-close" onClick={() => setModalOpen(false)} aria-label="Close"></button>
                        </div>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={DisplayingErrorMessagesSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ setFieldValue }) => (
                                <Form>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col mb-3">
                                                <div className="row g-2">
                                                    <div className="col mb-3">
                                                        <label htmlFor="name" className="form-label">Name</label>
                                                        <Field
                                                            name="name"
                                                            type="text"
                                                            className="form-control"
                                                        />
                                                        <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                                    </div>
                                                    
                                                    <div className="col mb-3">
                                                        <label htmlFor="image" className="form-label">Car Image</label>
                                                        <input
                                                            name="image"
                                                            type="file"
                                                            className="form-control"
                                                            onChange={(event) => setFieldValue('image', event.currentTarget.files[0])}
                                                        />
                                                        <ErrorMessage name="image" component="div" className="invalid-feedback" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-label-secondary" onClick={() => setModalOpen(false)}>Close</button>
                                        <button type="submit" className="btn btn-primary" disabled={loading}>
                                            {loading ? 'Adding...' : 'Add'}
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            {modalOpen && <div className="modal-backdrop fade show" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}></div>}
        </>
    );
};

export default ModalCarCategory;
