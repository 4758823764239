
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import {BASE_URL,BACKENDTOKEN} from '../../../../Constant/Constant';
import { toast } from 'react-toastify';

const BookingSummaryPage = () => {
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        VehicleHeaderTitle:'',
        ExtrasHeaderTitle:'',
        DetailsHeaderTitle:'',
        PaymentHeaderTitle:'',
        VehicleTitle:'',
        VehicleButtonTitle:'',
        ExtraTitle:'',
        ExtraButtonTitle:'',
        ExtraSelectButton:'',
        DetailsTitle:'',
        DetailsOptionTitle:'',
        DetailsButtonTitle:'',
        BillingAddressTitle:'',
        PaymentMethodTitle:'',
        PaymentMethodButtonTitle:'',
        PaymentMethodPoint1:'',
        PaymentMethodPoint2:'',
        RideSummaryTitle:'',
        TotalDistanceTitle:'',
        TotalTimeTitle:'',
        SummaryVehicleTitle:'',
        SummaryExtraOptions:'',
        SelectedvehicleTitle:'',
        TotalofExtraoptionsTitle:'',
        TotalTitle:'',
        RideSummaryPoint1:'',
        RideSummaryPoint2:'',
        RideSummaryPoint3:'',
        RideSummaryPoint4:'',
        
    });
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDataExists, setIsDataExists] = useState(false);
    const [languageId, setLanguageId] = useState(localStorage.getItem('selectedLanguageId'));
    const [id, setId] = useState(null); // Initialize with null

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${BASE_URL}staticcontent/get?language_id=${languageId}`;
                const response = await axios.get(url,{
                    headers:{
                        'Authorization': `Bearer ${BACKENDTOKEN}`
                    }
                });
                setId(response.data.id);
                if (response.data && response.data.bookingsummarypage) {
                    const parsedData = JSON.parse(response.data.bookingsummarypage);
                    setFormValues(parsedData);
                    setIsDataExists(true);
                    setIsEditMode(false); // Data exists, so not in edit mode
                } else {
                    setFormValues({
                        VehicleHeaderTitle:'',
                        ExtrasHeaderTitle:'',
                        DetailsHeaderTitle:'',
                        PaymentHeaderTitle:'',
                        VehicleTitle:'',
                        VehicleButtonTitle:'',
                        ExtraTitle:'',
                        ExtraButtonTitle:'',
                        ExtraSelectButton:'',
                        DetailsTitle:'',
                        DetailsOptionTitle:'',
                        DetailsButtonTitle:'',
                        BillingAddressTitle:'',
                        PaymentMethodTitle:'',
                        PaymentMethodButtonTitle:'',
                        PaymentMethodPoint1:'',
                        PaymentMethodPoint2:'',
                        RideSummaryTitle:'',
                        TotalDistanceTitle:'',
                        TotalTimeTitle:'',
                        SummaryVehicleTitle:'',
                        SummaryExtraOptions:'',
                        SelectedvehicleTitle:'',
                        TotalofExtraoptionsTitle:'',
                        TotalTitle:'',
                        RideSummaryPoint1:'',
                        RideSummaryPoint2:'',
                        RideSummaryPoint3:'',
                        RideSummaryPoint4:'',
                        
                    });
                    setIsDataExists(false);
                    setIsEditMode(true); // No data, so allow adding
                }
            } catch (error) {
                setIsEditMode(true); // Error fetching data, possibly allow editing
            }
        };

        fetchData();
    }, [languageId, id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const payload = {
                bookingsummarypage: formValues,
                language_id: languageId
            };

            const url = `${BASE_URL}staticcontent/bookingsummarypage`;
     

            const response = await axios.post(url, payload,{
                headers:{
                    'Authorization': `Bearer ${BACKENDTOKEN}`
                }
            });

            // Check if response data is in the expected format
            if (response.data && response.data.message === 'bookingsummarypage Process successfully!') {
                toast.dismiss()
                toast.success('Data processed successfully!', {
                    autoClose: 1500,
                });
                setIsDataExists(true);

            } else {
                toast.dismiss()
                toast.warn('Unexpected response from the server.', {
                    autoClose: 1500,
                });
            }

            setIsEditMode(false);
        } catch (error) {
            toast.dismiss()
            toast.error('Error saving data.', {
                autoClose: 1500,
            });
        } finally {
            setLoading(false);
        }
    };


    return (
        <>
            <form className="row g-3" onSubmit={handleSubmit}>
                <h5>Booking Header</h5>
                {/* First Row */}
                <div className="col-md-3">
                    <div className="mb-3">
                        <label className="form-label">VehicleHeader Title</label>
                        <input
                            name="VehicleHeaderTitle"
                            type="text"
                            className="form-control"
                            value={formValues.VehicleHeaderTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-3">
                        <label className="form-label">ExtrasHeader Title</label>
                        <input
                            name="ExtrasHeaderTitle"
                            type="text"
                            className="form-control"
                            value={formValues.ExtrasHeaderTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-3">
                        <label className="form-label">DetailsHeader Title</label>
                        <input
                            name="DetailsHeaderTitle"
                            type="text"
                            className="form-control"
                            value={formValues.DetailsHeaderTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-3">
                        <label className="form-label">PaymentHeader Title</label>
                        <input
                            name="PaymentHeaderTitle"
                            type="text"
                            className="form-control"
                            value={formValues.PaymentHeaderTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <h5>Vehicle Info</h5>
                {/* Second Row */}
                <div className="col-md-3">
                    <div className="mb-3">
                        <label className="form-label">Vehicle Title</label>
                        <input
                            name="VehicleTitle"
                            type="text"
                            className="form-control"
                            value={formValues.VehicleTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-3">
                        <label className="form-label">VehicleButton Title</label>
                        <input
                            name="VehicleButtonTitle"
                            type="text"
                            className="form-control"
                            value={formValues.VehicleButtonTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <h5>Extra Info</h5>
                {/* Second Row */}
                <div className="col-md-3">
                    <div className="mb-3">
                        <label className="form-label">Extra Title</label>
                        <input
                            name="ExtraTitle"
                            type="text"
                            className="form-control"
                            value={formValues.ExtraTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-3">
                        <label className="form-label">ExtraButton Title</label>
                        <input
                            name="ExtraButtonTitle"
                            type="text"
                            className="form-control"
                            value={formValues.ExtraButtonTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-3">
                        <label className="form-label">ExtraSelectButton Title</label>
                        <input
                            name="ExtraSelectButtonTitle"
                            type="text"
                            className="form-control"
                            value={formValues.ExtraSelectButtonTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <h5>Details Info</h5>
                {/* Second Row */}
                <div className="col-md-3">
                    <div className="mb-3">
                        <label className="form-label">Details Title</label>
                        <input
                            name="DetailsTitle"
                            type="text"
                            className="form-control"
                            value={formValues.DetailsTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-3">
                        <label className="form-label">DetailsOption Title</label>
                        <input
                            name="DetailsOptionTitle"
                            type="text"
                            className="form-control"
                            value={formValues.DetailsOptionTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-3">
                        <label className="form-label">DetailsButton Title</label>
                        <input
                            name="DetailsButtonTitle"
                            type="text"
                            className="form-control"
                            value={formValues.DetailsButtonTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>

                <h5>Payment Info</h5>
                {/* Second Row */}
                <div className="col-md-3">
                    <div className="mb-3">
                        <label className="form-label">BillingAddress Title</label>
                        <input
                            name="BillingAddressTitle"
                            type="text"
                            className="form-control"
                            value={formValues.BillingAddressTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-3">
                        <label className="form-label">PaymentMethod Title</label>
                        <input
                            name="PaymentMethodTitle"
                            type="text"
                            className="form-control"
                            value={formValues.PaymentMethodTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-3">
                        <label className="form-label">PaymentMethodButton Title</label>
                        <input
                            name="PaymentMethodButtonTitle"
                            type="text"
                            className="form-control"
                            value={formValues.PaymentMethodButtonTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-3">
                        <label className="form-label">PaymentMethod Point1</label>
                        <input
                            name="PaymentMethodPoint1"
                            type="text"
                            className="form-control"
                            value={formValues.PaymentMethodPoint1}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-3">
                        <label className="form-label">PaymentMethod Point2 </label>
                        <input
                            name="PaymentMethodPoint2"
                            type="text"
                            className="form-control"
                            value={formValues.PaymentMethodPoint2}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
               <h5>Ride Summary Info </h5> 
                <div className="col-md-3">
                    <div className="mb-3">
                        <label className="form-label">RideSummary Title</label>
                        <input
                            name="RideSummaryTitle"
                            type="text"
                            className="form-control"
                            value={formValues.RideSummaryTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-3">
                        <label className="form-label">TotalDistance Title</label>
                        <input
                            name="TotalDistanceTitle"
                            type="text"
                            className="form-control"
                            value={formValues.TotalDistanceTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-3">
                        <label className="form-label">TotalTime Title</label>
                        <input
                            name="TotalTimeTitle"
                            type="text"
                            className="form-control"
                            value={formValues.TotalTimeTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-3">
                        <label className="form-label">SummaryVehicle Title</label>
                        <input
                            name="SummaryVehicleTitle"
                            type="text"
                            className="form-control"
                            value={formValues.SummaryVehicleTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-3">
                        <label className="form-label">SummaryExtraOptions Title</label>
                        <input
                            name="SummaryExtraOptions"
                            type="text"
                            className="form-control"
                            value={formValues.SummaryExtraOptions}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-3">
                        <label className="form-label">Selectedvehicle Title</label>
                        <input
                            name="SelectedvehicleTitle"
                            type="text"
                            className="form-control"
                            value={formValues.SelectedvehicleTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-3">
                        <label className="form-label">TotalofExtraoptions Title</label>
                        <input
                            name="TotalofExtraoptionsTitle"
                            type="text"
                            className="form-control"
                            value={formValues.TotalofExtraoptionsTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-3">
                        <label className="form-label">Total Title</label>
                        <input
                            name="TotalTitle"
                            type="text"
                            className="form-control"
                            value={formValues.TotalTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>

                <div className="col-md-3">
                    <div className="mb-3">
                        <label className="form-label">RideSummary Point1</label>
                        <input
                            name="RideSummaryPoint1"
                            type="text"
                            className="form-control"
                            value={formValues.RideSummaryPoint1}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-3">
                        <label className="form-label">RideSummary Point2</label>
                        <input
                            name="RideSummaryPoint2"
                            type="text"
                            className="form-control"
                            value={formValues.RideSummaryPoint2}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-3">
                        <label className="form-label">RideSummary Point3</label>
                        <input
                            name="RideSummaryPoint3"
                            type="text"
                            className="form-control"
                            value={formValues.RideSummaryPoint3}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-3">
                        <label className="form-label">RideSummary Point4</label>
                        <input
                            name="RideSummaryPoint4"
                            type="text"
                            className="form-control"
                            value={formValues.RideSummaryPoint4}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                

               

                <div className="card-footer text-end">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loading}
                    >
                        {loading ? 'Saving...' : (isDataExists ? 'Update' : 'Add')}
                    </button>
                </div>

            </form>
        </>
    );
};

export default BookingSummaryPage;

