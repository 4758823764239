import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import '../../App.css'
import { BASE_URL, BACKENDTOKEN } from '../../Constant/Constant'
import { getTokenData } from '../../utils/decodeToken';

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [userId, setUserId] = useState(null);

  const passwordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[0-9]/, 'Password must contain at least one number')
      .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
      .required('New password is required'),
  });

  const validatePassword = async () => {
    try {
      await passwordSchema.validate({ newPassword });
      return true;
    } catch (error) {
      toast.error(error.message, {
        autoClose: 1500,             
});
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!(await validatePassword())) {
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error('New password and confirmation do not match', {
        autoClose: 1500,             
});
      return;
    }

    setLoading(true);

    try {
      const { userId } = getTokenData();

      if (!userId) {
        throw new Error('User ID or Branch ID not found');
      }
      setUserId(userId)

      const urls = `${BASE_URL}user/change-password/${userId}`;
      const response = await axios.post(urls, {
        currentPassword,
        newPassword,
      }, {
        headers: {
          'Authorization': `Bearer ${BACKENDTOKEN}`
        }
      });

      if (response.status === 200) {
        toast.success(response.data.message, {
          autoClose: 1500,             
});
 // Clear input fields
 setCurrentPassword('');
 setNewPassword('');
 setConfirmPassword('');
      } else {
        toast.error(response.data.message || 'Failed to change password', {
          autoClose: 1500,             
});
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'An error occurred while changing the password';
      toast.error(errorMessage, {
        autoClose: 1500,             
});
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-xl my-4">
      <h4 className="py-3 mb-4">
        <span className="text-muted fw-light">Account Settings /</span> ChangePassword
      </h4>
      <ul className="nav nav-pills flex-column flex-md-row mb-4">
        <li className="nav-item">
          <Link className="nav-link " to="../userProfile">
            <i className="ti-xs ti ti-users me-1"></i> Account
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link active" to="../changePassword">
            <i className="ti-xs ti ti-lock me-1"></i> Change Password
          </Link>
        </li>
      </ul>
      <div className="row row-cards">
        <div className="col-12">
          <div className="card">
            <div className="card-header header-underline">
              <div className='d-flex justify-content-between align-items-center'>
                <h5 className="card-title me-3">Change Password</h5>
              </div>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit} className="row g-3">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="currentPassword" className="form-label">Current Password</label>
                    <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                      <input
                        type={showCurrentPassword ? 'text' : 'password'}
                        id="currentPassword"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        className="form-control"
                        required
                        style={{ paddingRight: '40px' }} // Ensures space for the icon inside the input
                      />
                      <span
                        onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                        className='password-visible'
                      >
                        {showNewPassword ? <FaEye /> : <FaEyeSlash />}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">

                    <label htmlFor="newPassword" className='form-label'>New Password</label>
                    <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                      <input
                        type={showNewPassword ? 'text' : 'password'}
                        id="newPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className='form-control'
                        required
                        style={{ paddingRight: '40px' }}
                      />
                      <span
                        type="button"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        className='password-visible'
                      >
                        {showNewPassword ? <FaEye /> : <FaEyeSlash />}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">


                    <label htmlFor="confirmPassword" className='form-label'>Confirm New Password</label>
                    <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                      <input
                        type={showConfirmPassword ? 'text' : 'password'}
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className='form-control'
                        style={{ paddingRight: '40px' }}
                        required
                      />
                      <span
                        type="button"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        className='password-visible'
                      >
                        {showNewPassword ? <FaEye /> : <FaEyeSlash />}
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <h5>Password Rule</h5>
                  <ul style={{ marginTop: '0px', paddingLeft: '40px', color: '#555' }}>
                    <li>Password must be at least 8 characters</li>
                    <li>Password must contain at least one lowercase letter</li>
                    <li>Password must contain at least one uppercase letter</li>
                    <li>Password must contain at least one number</li>
                    <li>Password must contain at least one special character (e.g., !@#$%^&*(),.?&quot;:{ }|&lt;&gt;)</li>
                  </ul>
                </div>
                <div className="col-12 d-flex justify-content-end align-items-center">
                  <button type="submit" className="btn btn-primary" disabled={loading}>
                    {loading ? 'Changing...' : 'Change Password'}
                  </button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
