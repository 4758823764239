import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import AsideMenu from './AsideMenu';
import { RoutesAll } from '../routes';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import VerifyToken from '../Components/Token/VerifyToken';
import TokenExpiredPopup from '../Components/Token/TokenExpiredPopup';
import "../App.css";

const BaseLayout = () => {
    const navigate = useNavigate();
    const [showTokenExpiredPopup, setShowTokenExpiredPopup] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('BackendToken');
        if (!token) {
            navigate('/login'); // Redirect to login if token is not found
        }
    }, [navigate]);

    return (
        <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
            <div className="layout-container">
                <Header />
                <AsideMenu />
                <div className="layout-page">
                    <div className="content-wrapper">
                        <Routes>
                            {RoutesAll().map((route, index) => (
                                // Route without condition
                                route.condition === undefined ? (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        element={<route.component />}
                                    />
                                ) : (
                                    // Conditional route (redirect if not met)
                                    route.condition ? (
                                        <Route
                                            key={index}
                                            path={route.path}
                                            element={<route.component />}
                                        />
                                    ) : (
                                        <Route
                                            key={index}
                                            path={route.path}
                                            element={<Navigate to="/not-authorized" />}
                                        />
                                    )
                                )
                            ))}
                        </Routes>

                        {/* Token expiration popup and verification */}
                        {showTokenExpiredPopup && <TokenExpiredPopup />}
                        <VerifyToken onTokenInvalid={() => setShowTokenExpiredPopup(true)} />
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default BaseLayout;
