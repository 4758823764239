import React, { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import "../../App.css";
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from '../../Constant/Constant';
import { getTokenData } from '../../utils/decodeToken';

const UserProfile = () => {
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState({
        email: '',
        first_name: '',
        last_name: '',
        street_name: '',
        street_number: '',
        city: '',
        state: '',
        zip_code: '',
        mobile_number: ''
    });
    const [image, setImage] = useState('');
    const [isEditable, setIsEditable] = useState(false);
    const [imageChanged, setImageChanged] = useState(false);
    const [userId, setUserId] = useState(null);
    const [originalImage, setOriginalImage] = useState(''); // Store the original image URL

    useEffect(() => {
        const fetchData = async () => {
            try {
                const tokenData = getTokenData();
                const { userId } = tokenData;

                if (!userId) {
                    throw new Error('User ID not found');
                }

                setUserId(userId);
                const url = `${BASE_URL}user/get/${userId}`;
                const response = await axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('BackendToken')}`
                    }
                });

                const { first_name, last_name, email, mobile_number, image, street_name, street_number, city, state, zip_code } = response.data;

                setInitialValues({
                    first_name: first_name || '',
                    last_name: last_name || '',
                    email: email || '',
                    street_name: street_name || '',
                    street_number: street_number || '',
                    city: city || '',
                    state: state || '',
                    zip_code: zip_code || '',
                    mobile_number: mobile_number || ''
                });

                const imageUrl = image ? `${BASE_URL}uploads/${image}` : '';
                setImage(imageUrl);
                setOriginalImage(imageUrl); // Save the original image URL
            } catch (error) {
               toast.dismiss()
                toast.error('Error fetching profile data. Please try again.', {
                    autoClose: 1500,
                });
            }
        };

        fetchData();
    }, []);

    const handleEditClick = () => {
        setIsEditable(true);
    };

    const handleCancelClick = (formik) => {
        formik.resetForm();
        setIsEditable(false);
        setImageChanged(false);
        // Reset image to original URL or default if no image was initially set
        setImage(originalImage || '');
    };

    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required('First Name is required'),
        last_name: Yup.string().required('Last Name is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        street_name: Yup.string().required('Street Name is required'),
        street_number: Yup.string().required('Street Number is required'),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        zip_code: Yup.string().required('Zip Code is required'),
        mobile_number: Yup.string().required('Phone Number is required'),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        if (userId) {
            try {
                const formData = new FormData();
                formData.append('first_name', values.first_name);
                formData.append('last_name', values.last_name);
                formData.append('email', values.email);
                formData.append('street_name', values.street_name);
                formData.append('street_number', values.street_number);
                formData.append('city', values.city);
                formData.append('state', values.state);
                formData.append('zip_code', values.zip_code);
                formData.append('mobile_number', values.mobile_number);

                if (imageChanged) {
                    formData.append('image', document.getElementById('imageUpload').files[0]);
                }

                await axios.put(`${BASE_URL}user/update-profile/${userId}`, formData, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('BackendToken')}`
                    }
                });

                setIsEditable(false);
                toast.dismiss()
                toast.success('Profile updated successfully!', {
                    autoClose: 1500,
                });
            } catch (error) {
                toast.dismiss()
                toast.error('Error updating profile. Please try again.', {
                    autoClose: 1500,
                });
            }
        }
        setSubmitting(false);
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            const imageUrl = URL.createObjectURL(file);
            setImage(imageUrl);
            setImageChanged(true);
        } else {
            toast.dismiss()
            toast.error('Please select a valid image file.', {
                autoClose: 1500,
            });
        }
    };

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="py-3 mb-4">
                <span className="text-muted fw-light">Account Settings /</span> Account
            </h4>

            <div className="row">
                <div className="col-md-12">
                    <ul className="nav nav-pills flex-column flex-md-row mb-4">
                        <li className="nav-item">
                            <Link className="nav-link active" to="../userDetail">
                                <i className="ti-xs ti ti-users me-1"></i> Account
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="../changePassword">
                                <i className="ti-xs ti ti-lock me-1"></i> Change Password
                            </Link>
                        </li>
                    </ul>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {formik => (
                            <Form id="formAccountSettings">
                                <div className="card mb-4">
                                    <h5 className="card-header">Profile Details</h5>
                                    <div className="card-body">
                                        <div className='d-flex justify-content-start'>
                                            <img
                                                src={image || `${BASE_URL}uploads/default-avatar.png`} // Handle default image if image is empty
                                                alt="user-avatar"
                                                className="d-block w-px-100 h-px-100 rounded"
                                                id="uploadedAvatar"
                                            />
                                            {isEditable ? (
                                                <>
                                                    <label htmlFor="imageUpload" className="d-flex align-items-center text-primary mx-3" style={{ cursor: "pointer" }}>Change</label>
                                                    <input
                                                        className='border-0 p-0'
                                                        type="file"
                                                        id="imageUpload"
                                                        style={{ display: 'none' }}
                                                        onChange={handleImageChange}
                                                    />
                                                </>
                                            ) : null}
                                        </div>
                                    </div>
                                    <hr className="my-0" />
                                    <div className="card-body">
                                        <div className="row g-3">
                                            <div className="col-md-6">
                                                <label htmlFor="first_name" className="form-label">First Name</label>
                                                <Field
                                                    className="form-control"
                                                    type="text"
                                                    id="first_name"
                                                    name="first_name"
                                                    disabled={!isEditable}
                                                />
                                                <ErrorMessage name="first_name" component="div" className="text-danger" />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="last_name" className="form-label">Last Name</label>
                                                <Field
                                                    className="form-control"
                                                    type="text"
                                                    id="last_name"
                                                    name="last_name"
                                                    disabled={!isEditable}
                                                />
                                                <ErrorMessage name="last_name" component="div" className="text-danger" />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="email" className="form-label">E-mail</label>
                                                <Field
                                                    className="form-control"
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    disabled={!isEditable}
                                                />
                                                <ErrorMessage name="email" component="div" className="text-danger" />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="mobile_number" className="form-label">Phone Number</label>
                                                <Field
                                                    name="mobile_number"
                                                    render={({ field }) => (
                                                        <PhoneInput
                                                            {...field}
                                                            country={'us'}
                                                            placeholder="Enter phone number"
                                                            disabled={!isEditable}
                                                            inputStyle={{
                                                                width: "100%",
                                                                height: "40px",
                                                                paddingLeft: "44px",
                                                                border: "1px solid #ccc",
                                                                borderRadius: "5px",
                                                                fontSize: "16px",
                                                                backgroundColor: !isEditable ? "#f4f4f4" : "white",
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage name="mobile_number" component="div" className="text-danger" />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="street_name" className="form-label">Street Name</label>
                                                <Field
                                                    className="form-control"
                                                    type="text"
                                                    id="street_name"
                                                    name="street_name"
                                                    disabled={!isEditable}
                                                />
                                                <ErrorMessage name="street_name" component="div" className="text-danger" />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="street_number" className="form-label">House Number</label>
                                                <Field
                                                    className="form-control"
                                                    type="text"
                                                    id="street_number"
                                                    name="street_number"
                                                    disabled={!isEditable}
                                                />
                                                <ErrorMessage name="street_number" component="div" className="text-danger" />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="zip_code" className="form-label">Zip Code</label>
                                                <Field
                                                    className="form-control"
                                                    type="text"
                                                    id="zip_code"
                                                    name="zip_code"
                                                    disabled={!isEditable}
                                                />
                                                <ErrorMessage name="zip_code" component="div" className="text-danger" />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="city" className="form-label">City</label>
                                                <Field
                                                    className="form-control"
                                                    type="text"
                                                    id="city"
                                                    name="city"
                                                    disabled={!isEditable}
                                                />
                                                <ErrorMessage name="city" component="div" className="text-danger" />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="state" className="form-label">State</label>
                                                <Field
                                                    className="form-control"
                                                    type="text"
                                                    id="state"
                                                    name="state"
                                                    disabled={!isEditable}
                                                />
                                                <ErrorMessage name="state" component="div" className="text-danger" />
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <hr className="my-0" />
                                    <div className="card-body">
                                        {isEditable ? (
                                            <div className="d-flex justify-content-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary me-2"
                                                    onClick={() => handleCancelClick(formik)}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    disabled={formik.isSubmitting}
                                                >
                                                    Save changes
                                                </button>
                                            </div>
                                        ) : (
                                            <div className="d-flex justify-content-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={handleEditClick}
                                                >
                                                    Edit
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
         
        </div>
    );
};

export default UserProfile;
