import { useEffect } from 'react';
import { BASE_URL } from '../../Constant/Constant';

const VerifyToken = ({ onTokenInvalid }) => {
  const token = localStorage.getItem('BackendToken'); // Get token from localStorage
  const userId = localStorage.getItem('userId'); // Get userId from localStorage

  const clearAuthToken = () => {
    localStorage.removeItem('BackendToken');
    localStorage.removeItem('userId');
  };

  const checkTokenExpiry = async () => {
    if (!token) {
      console.log('No token found, triggering token invalidation.');
      onTokenInvalid();
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}user/verify-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token,
          id: userId, // Pass the id if needed
        }),
      });

      if (!response.ok) {
        // Log the HTTP error response
        console.error(`HTTP error! Status: ${response.status}`);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Token verification response:', data);

      if (!data.valid) {
        if (data.message === 'JWT expired') {
          console.log('Token expired, clearing token and triggering invalidation.');
        } else {
          console.log('Token invalid, clearing token and triggering invalidation.');
        }
        clearAuthToken(); // Clear the token from localStorage
        onTokenInvalid(); // Handle token invalidation (e.g., redirect to login)
      }
    } catch (error) {
      console.error('Token verification error:', error);
      clearAuthToken();
      onTokenInvalid();
    }
  };

  useEffect(() => {
    checkTokenExpiry();
  }, [token, userId]); // Add token and userId to dependency array to handle changes

  return null;
};

export default VerifyToken;
