import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the CSS for ReactQuill
import { MdOutlineCancel } from 'react-icons/md';
import { IoAddCircleOutline } from 'react-icons/io5';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BASE_URL,BACKENDTOKEN} from '../../Constant/Constant'
import { useSelector } from 'react-redux';

const ActionServices = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { id } = useParams();
  const action = queryParams.get('action');
  const navigate = useNavigate();
  const [points, setPoints] = useState([]);
  const [carDetails, setCarDetails] = useState({
    title: '',
    Image: '',
    short_description: '',
    preview_image: '',
    service_category: '',
    description: ''
  });
  const [isEditable, setIsEditable] = useState(false);
  const [message, setMessage] = useState(null);
   
const permissions = useSelector(state => state.roles.permissions.user_modules);
const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });

useEffect(() => {
    if (permissions) {
        try {
            const modules = JSON.parse(permissions);
            const services = modules.find(module => module.rolename === 'services');
            if (services) {
                setRolePermissions(services.permissions);
            }
        } catch (error) {
            console.error('Failed to parse permissions:', error);
        }
    }
}, [permissions]);

  useEffect(() => {
    if (id) {
      axios.get(`${BASE_URL}service/get/${id}`, {
        headers: {
            'Authorization': `Bearer ${BACKENDTOKEN}`
        }
    })
        .then(response => {
          const data = response.data;
          data.points = Array.isArray(data.points) ? data.points : JSON.parse(data.points || '[]');
          setPoints(data.points);

          setCarDetails({
            title: data.title || '',
            Image: data.image || '',
            preview_image: data.preview_image || '',
            short_description: data.short_description || '',
            service_category: data.service_category || '',
            description: data.description || ''
          });
        })
        .catch(error => {
          console.error('Error fetching service details:', error);
        });
    }
  }, [id]);

  useEffect(() => {
    setIsEditable(action === 'edit');
  }, [action]);

  const handleUpdate = () => {
    if (rolePermissions.write === 'no') {
      toast.dismiss();
      toast.error('You do not have permission to update data.', {
          autoClose: 1500,             
  });
      return;
  }
    // Validate required fields
    if (!carDetails.title || !carDetails.short_description || !carDetails.service_category || !carDetails.description) {
      setMessage('Please fill in all required fields.');
      setTimeout(() => setMessage(null), 2000);
      return;
    }

    // Create FormData and append fields
    const formData = new FormData();
    formData.append('title', carDetails.title);
    formData.append('short_description', carDetails.short_description);
    formData.append('service_category', carDetails.service_category);
    formData.append('description', carDetails.description);

    // Append file fields if they are present
    if (carDetails.Image) {
      formData.append('Image', carDetails.Image);
    }
    if (carDetails.preview_image) {
      formData.append('preview_image', carDetails.preview_image);
    }

    // Append points as a JSON string
    formData.append('points', JSON.stringify(points));

    // Perform the update request
    axios.put(`${BASE_URL}service/update/${id}`, formData, {
      headers: {
         'Authorization': `Bearer ${BACKENDTOKEN}`,
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        toast.dismiss();
        toast.success('Service details updated successfully', {
          autoClose: 1500,             
});
        setTimeout(() => {
          setMessage(null);
          navigate(`/actionservice/${id}?action=view`);
        });
      })
      .catch(error => {
        console.error('Error updating service details:', error);
        toast.dismiss();
        toast.error('Failed to update service details', {
          autoClose: 1500,             
});
        setTimeout(() => {
          setMessage(null);
        }, 2000);
      });
  };

  const handleEdit = () => {
    navigate(`/actionservice/${id}?action=edit`);
  };

  const handleCancel = () => {
    navigate(`/actionservice/${id}?action=view`);
  };

  const handleFieldChange = (e) => {
    const { name, value, type, files } = e.target;
    setCarDetails(prevState => ({
      ...prevState,
      [name]: type === 'file' ? files[0] : value
    }));
  };

  const handleDescriptionChange = (value) => {
    setCarDetails(prevState => ({
      ...prevState,
      description: value
    }));
  };

  const handlePointChange = (e, index) => {
    const newPoints = [...points];
    newPoints[index] = e.target.value;
    setPoints(newPoints);
  };

  const handleRemovePoint = (index) => {
    const newPoints = [...points];
    newPoints.splice(index, 1);
    setPoints(newPoints);
  };

  const handleAddPoint = () => {
    setPoints([...points, '']);
  };

  return (
    <div className="container-xl my-4">
      <ToastContainer />
      <div className="row">
        <div className="col-md-6">
          <h4>Services</h4>
        </div>
        <div className="col-md-6">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-end fs-3">
              <li className="breadcrumb-item"><Link to="/serviceslist" className='fs-6'>Back</Link></li>
              <li className="breadcrumb-item"><Link to="#" className='fs-6'>ActionPage</Link></li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="col-12 mt-3">
        <div className="card mt-3">
          <div className="card-header header-underline pb-1">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="card-name">{action === 'edit' ? 'Edit Details' : 'View Details'}</h5>
            </div>
          </div>
          <div className="card-body mt-3">
            <form >
              <div className="row g-2 mb-3">
                <div className="col-sm-3">
                  <label className="col-sm col-form-label" htmlFor="title">Title</label>
                  <input
                    type="text"
                    name="title"
                    value={carDetails.title}
                    onChange={handleFieldChange}
                    disabled={!isEditable}
                    className="form-control mb-2"
                  />
                </div>
                <div className="col-sm-3">
                  <label className="col-sm col-form-label" htmlFor="service_category">Service Category</label>
                  <select
                    name="service_category"
                    value={carDetails.service_category}
                    onChange={handleFieldChange}
                    disabled={!isEditable}
                    className="form-control mb-2"
                  >
                    <option value="">Select</option>
                    <option value="Business Class">Business Class</option>
                    <option value="Chauffeur Hailing">Chauffeur Hailing</option>
                    <option value="Airport Transfers">Airport Transfers</option>
                    <option value="Sprinter Class">Sprinter Class</option>
                    <option value="Wedding Class">Wedding Class</option>
                    <option value="Travel Transfer">Travel Transfer</option>
                  </select>
                </div>

                <div className="col-sm-6">
                  <label className="col-sm col-form-label" htmlFor="short_description">Short Description</label>
                  <input
                    type="text"
                    name="short_description"
                    value={carDetails.short_description}
                    onChange={handleFieldChange}
                    disabled={!isEditable}
                    className="form-control mb-2"
                  />
                </div>
              </div>

              <div className="row g-2 mb-3">
                <div className="col-sm-3">
                  <label className="col-sm col-form-label" htmlFor="Image">Image</label>
                  <div className="mb-3">
                    {carDetails.Image && (
                      <img
                        src={`${BASE_URL}uploads/${carDetails.Image}`}
                        alt="Image Preview"
                        className="img-fluid mb-2"
                        style={{ height: '220px', width: '100%', borderRadius: "5px" }}
                      />
                    )}
                  </div>
                  {isEditable && (
                    <input
                      type="file"
                      name="Image"
                      onChange={handleFieldChange}
                      disabled={!isEditable}
                      className="form-control mb-2"
                    />
                  )}
                </div>
                <div className="col-sm-3">
                  <label className="col-sm col-form-label" htmlFor="preview_image">Preview Image</label>
                  <div className="mb-3">
                    {carDetails.preview_image && (
                      <img
                        src={`${BASE_URL}uploads/${carDetails.preview_image}`}
                        alt="Preview Image"
                        className="img-fluid mb-2"
                        style={{ height: '220px', width: '100%', borderRadius: "5px" }}
                      />
                    )}
                  </div>
                  {isEditable && (
                    <input
                      type="file"
                      name="preview_image"
                      onChange={handleFieldChange}
                      disabled={!isEditable}
                      className="form-control mb-2"
                    />
                  )}
                </div>
                <div className="col-sm-6">
                  <label className="col-sm col-form-label">Description</label>
                  {isEditable ? (
                    <ReactQuill
                      theme="snow"
                      value={carDetails.description}
                      onChange={handleDescriptionChange}
                      modules={{
                        toolbar: [
                          [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                          [{ 'size': [] }],
                          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                          ['link', 'image'],
                          ['clean']
                        ]
                      }}
                      style={{ height: '250px', borderRadius: '10px' }}
                      className="react-quill-editor mb-3"
                    />
                  ) : (
                    <div
                      className="form-control mb-2"
                      dangerouslySetInnerHTML={{ __html: carDetails.description }}
                      style={{ minHeight: '250px', backgroundColor: '#f4f4f4' }}
                    />
                  )}
                </div>
              </div>

              <div className="col-md-6 ">
                <label className="col-sm col-form-label">Points:</label>
                <div className=''>
                  {points.map((point, index) => (
                    <div key={index} className='input-group mb-3 '>
                      <input
                        type="text"
                        value={point}
                        onChange={(e) => handlePointChange(e, index)}
                        required
                        disabled={!isEditable}
                        className="form-control "
                      />
                      {isEditable && (
                        <button type="button" className='btn btn-label-secondary  waves-effect waves-light' onClick={() => handleRemovePoint(index)}>
                          <MdOutlineCancel />
                        </button>
                      )}
                    </div>
                  ))}
                  {isEditable && (
                    <button type="button" className="btn btn-primary" onClick={handleAddPoint}>
                      <IoAddCircleOutline style={{ fontSize: "16px" }} />
                    </button>
                  )}
                </div>
              </div>
            </form>
            </div>
            <div className='card-footer'>
              <div className="d-flex justify-content-end align-items-center">
                {action === 'view' &&   rolePermissions.write === 'yes'  && (
                  <button type="button" onClick={handleEdit} className="btn btn-primary ">Edit</button>
                )}
                {isEditable && (
                  <button type="button" onClick={handleUpdate} className="btn btn-primary mx-3">Update</button>
                )}
                {action === 'edit' && (
                  <button type="button" onClick={handleCancel} className=' btn btn-label-secondary'>Cancel</button>
                )}
              </div>
            </div>
         
        </div >
      </div >
    </div >
  );
};

export default ActionServices;
