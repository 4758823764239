import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL, BACKENDTOKEN } from '../../../../Constant/Constant';
import { toast } from 'react-toastify';

const RegisterPage = () => {
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        Title: '',
        SignUpButtonTitle: '',
        AlreadyRegisteredTitle: '',
        AlreadyRegisteredLinkTitle: '',
        Description:''
    });
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDataExists, setIsDataExists] = useState(false);
    const [languageId] = useState(localStorage.getItem('selectedLanguageId'));

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${BASE_URL}staticcontent/get?language_id=${languageId}`;
                const response = await axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${BACKENDTOKEN}`,
                    },
                });

                if (response.data && response.data.registerpage) {
                    const parsedData = JSON.parse(response.data.registerpage);
                    setFormValues(parsedData);
                    setIsDataExists(true);
                    setIsEditMode(false); // Disable edit mode if data exists
                } else {
                    setFormValues({
                        Title: '',
                        SignUpButtonTitle: '',
                        AlreadyRegisteredTitle: '',
                        AlreadyRegisteredLinkTitle: '',
                        Description:''
                    });
                    setIsDataExists(false);
                    setIsEditMode(true); // Enable edit mode if no data
                }
            } catch (error) {
                setIsEditMode(true); // Allow editing if there's an error
            }
        };

        fetchData();
    }, [languageId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const payload = {
                registerpage: JSON.stringify(formValues), // Ensure formValues is stringified
                language_id: languageId,
            };

            const url = `${BASE_URL}staticcontent/registerpage`;

            const response = await axios.post(url, payload, {
                headers: {
                    Authorization: `Bearer ${BACKENDTOKEN}`,
                },
            });

            if (response.data && response.data.message === 'registerpage Process successfully!') {
                toast.success('Data processed successfully!', { autoClose: 1500 });
                setIsDataExists(true);
                setIsEditMode(false); // Disable edit mode after successful save
            } else {
                toast.warn('Unexpected response from the server.', { autoClose: 1500 });
            }
        } catch (error) {
            toast.error('Error saving data.', { autoClose: 1500 });
        } finally {
            setLoading(false);
        }
    };

    return (
        <form className="row g-3" onSubmit={handleSubmit}>
            {/* First Row */}
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Title</label>
                    <input
                        name="Title"
                        type="text"
                        className="form-control"
                        value={formValues.Title}
                        onChange={handleChange}
                       
                    />
                </div>
            </div>
            <div className="col-md-4">
                        <label className="form-label">Description</label>
                        <textarea
                            name="Description"
                            className="form-control"
                            rows="2" // This will create a 2-row textarea
                            value={formValues.Description}
                            onChange={handleChange}
                            disabled={loading}
                        />
                </div>
         

            {/* Second Row
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Moblie Title</label>
                    <input
                        name="MoblieTitle"
                        type="text"
                        className="form-control"
                        value={formValues.MoblieTitle}
                        onChange={handleChange}
                       
                    />
                </div>
            </div>

            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Email Title</label>
                    <input
                        name="EmailTitle"
                        type="text"
                        className="form-control"
                        value={formValues.EmailTitle}
                        onChange={handleChange}
                       
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Password Title</label>
                    <input
                        name="PasswordTitle"
                        type="text"
                        className="form-control"
                        value={formValues.PasswordTitle}
                        onChange={handleChange}
                       
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">confirm password Title</label>
                    <input
                        name="confirmPasswordTitle"
                        type="text"
                        className="form-control"
                        value={formValues.confirmPasswordTitle}
                        onChange={handleChange}
                       
                    />
                </div>
            </div> */}
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">SignUpButton Title</label>
                    <input
                        name="SignUpButtonTitle"
                        type="text"
                        className="form-control"
                        value={formValues.SignUpButtonTitle}
                        onChange={handleChange}
                       
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">AlreadyRegistered Title</label>
                    <input
                        name="AlreadyRegisteredTitle"
                        type="text"
                        className="form-control"
                        value={formValues.AlreadyRegisteredTitle}
                        onChange={handleChange}
                       
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">AlreadyRegisteredLink Title</label>
                    <input
                        name="AlreadyRegisteredLinkTitle"
                        type="text"
                        className="form-control"
                        value={formValues.AlreadyRegisteredLinkTitle}
                        onChange={handleChange}
                       
                    />
                </div>
            </div>

            <div className="card-footer text-end">
                <button type="submit" className="btn btn-primary" disabled={loading}>
                    {loading ? 'Saving...' : isDataExists ? 'Update' : 'Add'}
                </button>
            </div>
        </form>
    );
};

export default RegisterPage;
