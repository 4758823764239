import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL, BACKENDTOKEN } from '../../../../Constant/Constant';
import { toast } from 'react-toastify';

const ServiceIndex = () => {
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        ServiceTitle1: '',
        Description1: '',
        ServiceTitle2: '',
        Description2: '',
        ServiceTitle3: '',
        Description3: ''
    });
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDataExists, setIsDataExists] = useState(false);
    const [languageId, setLanguageId] = useState(localStorage.getItem('selectedLanguageId'));
    const [id, setId] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${BASE_URL}staticcontent/get?language_id=${languageId}`;
                const response = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${BACKENDTOKEN}`,
                    },
                });

                setId(response.data.id);
                if (response.data && response.data.serviceindex) {
                    const parsedData = JSON.parse(response.data.serviceindex);
                    setFormValues(parsedData);
                    setIsDataExists(true);
                    setIsEditMode(false);
                } else {
                    setFormValues({
                        ServiceTitle1: '',
                        Description1: '',
                        ServiceTitle2: '',
                        Description2: '',
                        ServiceTitle3: '',
                        Description3: ''
                    });
                    setIsDataExists(false);
                    setIsEditMode(true);
                }
            } catch (error) {
                setIsEditMode(true);
            }
        };

        fetchData();
    }, [languageId, id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const payload = {
                serviceindex: formValues,
                language_id: languageId,
            };

            const url = `${BASE_URL}staticcontent/serviceindex`;
            const response = await axios.post(url, payload, {
                headers: {
                    Authorization: `Bearer ${BACKENDTOKEN}`,
                },
            });

            if (response.data && response.data.message === 'serviceindex Process successfully!') {
                toast.success('Data processed successfully!', {
                    autoClose: 1500,
                });
                setIsDataExists(true);
            } else {
                toast.warn('Unexpected response from the server.', {
                    autoClose: 1500,
                });
            }

            setIsEditMode(false);
        } catch (error) {
            toast.error('Error saving data.', {
                autoClose: 1500,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <form className="row g-3" onSubmit={handleSubmit}>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">Service Title1</label>
                        <input
                            name="ServiceTitle1"
                            type="text"
                            className="form-control"
                            value={formValues.ServiceTitle1}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">Service Description1</label>
                        <textarea
                            name="Description1"
                            className="form-control"
                            value={formValues.Description1}
                            onChange={handleChange}
                            rows="2"
                            disabled={loading}
                        ></textarea>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">Service Title2</label>
                        <input
                            name="ServiceTitle2"
                            type="text"
                            className="form-control"
                            value={formValues.ServiceTitle2}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">Service Description2</label>
                        <textarea
                            name="Description2"
                            className="form-control"
                            value={formValues.Description2}
                            onChange={handleChange}
                            rows="2"
                            disabled={loading}
                        ></textarea>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">Service Title3</label>
                        <input
                            name="ServiceTitle3"
                            type="text"
                            className="form-control"
                            value={formValues.ServiceTitle3}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">Service Description3</label>
                        <textarea
                            name="Description3"
                            className="form-control"
                            value={formValues.Description3}
                            onChange={handleChange}
                            rows="2"
                            disabled={loading}
                        ></textarea>
                    </div>
                </div>

                <div className="card-footer text-end mt-5">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loading}
                    >
                        {loading ? 'Saving...' : isDataExists ? 'Update' : 'Save'}
                    </button>
                </div>
            </form>
        </>
    );
};

export default ServiceIndex;
