

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {BASE_URL,BACKENDTOKEN} from '../../../../Constant/Constant';
import { toast } from 'react-toastify';


const VerifyOTPPage = () => {
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        OTPPageTitle: '',
        OTPPageSlug: '',
        OTPPageButtonTitle: '',
        CreateTitle: '',
        OTPPageDescription:''
    });
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDataExists, setIsDataExists] = useState(false);
    const [languageId, setLanguageId] = useState(localStorage.getItem('selectedLanguageId'));
    const [id, setId] = useState(null); // Initialize with null

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${BASE_URL}staticcontent/get?language_id=${languageId}`;
                const response = await axios.get(url,{
                    headers:{
                        'Authorization': `Bearer ${BACKENDTOKEN}`
                    }
                });

                setId(response.data.id);
                if (response.data && response.data.verifyotppage) {
                    const parsedData = JSON.parse(response.data.verifyotppage);
                    setFormValues(parsedData);
                    setIsDataExists(true);
                    setIsEditMode(false); // Data exists, so not in edit mode


                } else {

                    setFormValues({
                        OTPPageTitle: '',
                        OTPPageSlug: '',
                        OTPPageButtonTitle: '',
                        CreateTitle: '',
                        OTPPageDescription:''
                    });
                    setIsDataExists(false);
                    setIsEditMode(true); // No data, so allow adding
                }
            } catch (error) {
                setIsEditMode(true); // Error fetching data, possibly allow editing
            }
        };

        fetchData();
    }, [languageId, id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const payload = {
                verifyotppage: formValues,
                language_id: languageId
            };

            const url = `${BASE_URL}staticcontent/verifyotppage`;

            const response = await axios.post(url, payload,{
                headers:{
                    'Authorization': `Bearer ${BACKENDTOKEN}`
                }
            });

            // Check if response data is in the expected format
            if (response.data && response.data.message === 'verifyotppage Process successfully!') {
                toast.dismiss()
                toast.success('Data processed successfully!', {
                    autoClose: 1500,
                });
                setIsDataExists(true);

            } else {
                toast.dismiss()
                toast.warn('Unexpected response from the server.', {
                    autoClose: 1500,
                });
            }

            setIsEditMode(false);
        } catch (error) {
            toast.dismiss()
            toast.error('Error saving data.', {
                autoClose: 1500,
            });
        } finally {
            setLoading(false);
        }
    };


    return (
        <>
            <form className="row g-3" onSubmit={handleSubmit}>
                {/* First Row */}
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">OTPPage Title</label>
                        <input
                            name="OTPPageTitle"
                            type="text"
                            className="form-control"
                            value={formValues.OTPPageTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                        <label className="form-label">OTPPageDescription</label>
                        <textarea
                            name="OTPPageDescription"
                            className="form-control"
                            rows="2" // This will create a 2-row textarea
                            value={formValues.OTPPageDescription}
                            onChange={handleChange}
                            disabled={loading}
                        />
                </div>
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">AlreadyRegistered Title </label>
                        <input
                            name="OTPPageSlug"
                            type="text"
                            className="form-control"
                            value={formValues.OTPPageSlug}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">OTPPage ButtonTitle</label>
                        <input
                            name="OTPPageButtonTitle"
                            type="text"
                            className="form-control"
                            value={formValues.OTPPageButtonTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>

                {/* Second Row */}
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">AlreadyRegisteredLink Title</label>
                        <input
                            name="CreateTitle"
                            type="text"
                            className="form-control"
                            value={formValues.CreateTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>

                <div className="card-footer text-end">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loading}
                    >
                        {loading ? 'Saving...' : (isDataExists ? 'Update' : 'Add')}
                    </button>
                </div>

            </form>
        </>
    );
};

export default VerifyOTPPage;

