import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {BASE_URL,BACKENDTOKEN} from '../../../Constant/Constant';
import { useSelector } from 'react-redux';

const ActionContact = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { id } = useParams();
  const action = queryParams.get('action');
  const navigate = useNavigate();


  const [branch_type, setbranch_type] = useState('');
  const [branch_name, setbranch_name] = useState('');
  const [street, setstreet] = useState('');
  const [number, setnumber] = useState('');
  const [postal_code, setpostal_code] = useState('');
  const [city, setcity] = useState('');
  const [state, setstate] = useState('');
  const [working_hours, setworking_hours] = useState('');
  const [email, setemail] = useState('');
  const [mobile, setmobile] = useState('');

  const [isEditable, setIsEditable] = useState(false);
  const [message, setMessage] = useState(null);
    
const permissions = useSelector(state => state.roles.permissions.user_modules);
const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });

useEffect(() => {
    if (permissions) {
        try {
            const modules = JSON.parse(permissions);
            const contactus = modules.find(module => module.rolename === 'contactus');
            if (contactus) {
                setRolePermissions(contactus.permissions);
            }
        } catch (error) {
            console.error('Failed to parse permissions:', error);
        }
    }
}, [permissions]);

  useEffect(() => {
    axios.get(`${BASE_URL}contact/get/${id}`, {
      headers: {
          'Authorization': `Bearer ${BACKENDTOKEN}`
      }
  })
      .then(response => {
        setbranch_type(response.data.branch_type);
        setbranch_name(response.data.branch_name);
        setstreet(response.data.street);
        setnumber(response.data.number);
        setpostal_code(response.data.postal_code);
        setcity(response.data.city);
        setstate(response.data.state);
        setworking_hours(response.data.working_hours);
        setemail(response.data.email);
        setmobile(response.data.mobile);

      })
      .catch(error => {
        console.error('Error fetching company:', error);
      });
  }, [id]);

  useEffect(() => {
    // Set editable state based on action
    setIsEditable(action === 'edit');
  }, [action]);

  const handleUpdate = () => {
    
if (rolePermissions.write === 'no') {
  toast.dismiss();
  toast.error('You do not have permission to update data.', {
      autoClose: 1500,             
});
  return;
}
    axios.put(`${BASE_URL}contact/update/${id}`, {
      branch_type, branch_name, street, number, postal_code, city, state, working_hours, email, mobile
    }, {
      headers: {
          'Authorization': `Bearer ${BACKENDTOKEN}`
      }
  })
      .then(response => {
        toast.dismiss();
        toast.success('company updated successfully', {
          autoClose: 1500,             
});
        setTimeout(() => {
          setMessage(null);
          navigate(`/ActionContact/${id}?action=view`);
        }); 
      })
      .catch(error => {
        toast.dismiss();
        toast.error('Failed to update company', {
          autoClose: 1500,             
});
        setTimeout(() => {
          setMessage(null);
        }, 2000); // Clear message after 2 seconds
      });
  };

  const handleEdit = () => {
    // Redirect to the same URL but with action=edit
    navigate(`/ActionContact/${id}?action=edit`);
  };

  const handleCancel = () => {
    // Redirect to the same URL but with action=view
    navigate(`/ActionContact/${id}?action=view`);
  };

  return (
    <div className="container-xl my-4">
<ToastContainer />
      <div className="row">
        <div className="col-md-6">
          <h4>ContactUs</h4>
        </div>
        <div className="col-md-6">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-end fs-3">
              <li className="breadcrumb-item"><Link to="/contactus" className='fs-6'>Back</Link></li>
              <li className="breadcrumb-item"><Link to="#" className='fs-6'>ActionPage</Link></li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="col-12 mt-3">
        <div className="card mt-3">
          <div className="card-header header-underline pb-1">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="card-name">{action === 'edit' ? 'Edit Details' : 'View Details'}</h5>
            </div>
          </div>
          <div className="card-body mt-3">
            <form className="row g-3">
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="col-sm col-form-label" htmlFor="branch_type">Branch Type</label>
                  <select
                    id="branch_type"
                    value={branch_type}
                    onChange={e => setbranch_type(e.target.value)}
                    disabled={!isEditable}
                    className="form-select mb-2"
                  >
                    <option value="">Branch Type</option>
                    <option value="MainBranch">Main Branch</option>
                    <option value="SubBranch">Sub Branch</option>
                  </select>
                </div>

                <div className=" mb-3">


                  <label className="col-form-label" htmlFor="number">
                    Number
                  </label>
                  <input
                    type="number"
                    value={number}
                    onChange={e => setnumber(e.target.value)}
                    disabled={!isEditable}
                    className="form-control mb-2"

                  />
                </div>
                <div className=" mb-3">
                  <label className="col-form-label" htmlFor="postal_code">
                    Postal Code
                  </label>
                  <input
                    type="text"
                    value={postal_code}
                    onChange={e => setpostal_code(e.target.value)}
                    disabled={!isEditable}
                    className="form-control mb-2"

                  />

                </div>



                <div className="mb-3">
                  <label className="col-sm-2 col-form-label" htmlFor="city">
                    City
                  </label>
                  <input
                    type="text"
                    value={city}
                    onChange={e => setcity(e.target.value)}
                    disabled={!isEditable}
                    className="form-control mb-2"

                  />
                </div>
                <div className="mb-3">
                  <label className="col-sm-2 col-form-label" htmlFor="state">
                    State
                  </label>
                  <input
                    type="text"
                    value={state}
                    onChange={e => setstate(e.target.value)}
                    disabled={!isEditable}
                    className="form-control mb-2"

                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="col-form-label" htmlFor="branch_name">
                    Branch Name
                  </label>
                  <input
                    type="text"
                    value={branch_name}
                    onChange={e => setbranch_name(e.target.value)}
                    disabled={!isEditable}
                    className="form-control mb-2"

                  />
                </div>

                <div className="mb-3">
                  <label className="col-form-label" htmlFor="working_hours">
                    Working Hours
                  </label>
                  <input
                    type="text"
                    value={working_hours}
                    onChange={e => setworking_hours(e.target.value)}
                    disabled={!isEditable}
                    className="form-control mb-2"

                  />
                </div>
                <div className="mb-3">
                  <label className="col-sm-2 col-form-label" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="text"
                    value={email}
                    onChange={e => setemail(e.target.value)}
                    disabled={!isEditable}
                    className="form-control mb-2"

                  />
                </div>
                <div className="mb-3">
                  <label className="col-sm-2 col-form-label" htmlFor="mobile">
                    Mobile
                  </label>
                  <PhoneInput
                    country={'us'} // Set the default country code or adjust as needed
                    value={mobile}
                    onChange={value => setmobile(value)} // Adjust for PhoneInput value
                    disabled={!isEditable}
                    inputStyle={{
                      width: "100%",
                      height: "40px",
                      border: "1px solid #ced4da",
                      borderRadius: "0.25rem",
                      fontSize: "16px",
                      backgroundColor: !isEditable ? '#f4f4f4' : 'transparent', 
                    }}
                    containerStyle={{ marginBottom: "1rem" }} // Optional: Adjust margin for spacing
                  />
                </div>
                <div className="mb-3">
                  <label className="col-sm-2 col-form-label" htmlFor="street">
                    Street
                  </label>
                  <input
                    type="text"
                    value={street}
                    onChange={e => setstreet(e.target.value)}
                    disabled={!isEditable}
                    className="form-control mb-2"

                  />
                </div>


              </div>
            </form>
            </div>
            <div className='card-footer'>
              <div className="d-flex justify-content-end align-items-center">
                {action === 'view' && rolePermissions.write === 'yes' && (
                  <button type="button" onClick={handleEdit} className="btn btn-primary mb-2" >Edit</button>
                )}

                {isEditable && (
                  <button type="button" onClick={handleUpdate} className="btn btn-primary mx-3  " >Update </button>
                )}
                {action === 'edit' && (
                  <button type="button" onClick={handleCancel} className='btn btn-label-secondary'>Cancel</button>
                )}
              </div>
              {message && <div className="message text-primary">{message}</div>}
            </div>
        
        </div>
      </div>
    </div>
  );
};

export default ActionContact;
