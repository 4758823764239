import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { BASE_URL, BACKENDTOKEN } from '../../../Constant/Constant'
import { useSelector } from 'react-redux';

const ActionFAQ = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { id } = useParams();
  const action = queryParams.get('action');
  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isEditable, setIsEditable] = useState(false);
  const [message, setMessage] = useState(null);
  
const permissions = useSelector(state => state.roles.permissions.user_modules);
const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });

useEffect(() => {
    if (permissions) {
        try {
            const modules = JSON.parse(permissions);
            const faq = modules.find(module => module.rolename === 'faq');
            if (faq) {
                setRolePermissions(faq.permissions);
            }
        } catch (error) {
            console.error('Failed to parse permissions:', error);
        }
    }
}, [permissions]);

  useEffect(() => {
    axios.get(`${BASE_URL}faq/get/${id}`, {
      headers: {
        'Authorization': `Bearer ${BACKENDTOKEN}`
      }
    })
      .then(response => {
        setTitle(response.data.title);
        setDescription(response.data.description);
      })
      .catch(error => {
        toast.dismiss()
        toast.error('Error fetching FAQ:', error, {
          autoClose: 1500,
        });
      });
  }, [id]);

  useEffect(() => {
    setIsEditable(action === 'edit');
  }, [action]);

  const handleUpdate = () => {
    if (rolePermissions.write === 'no') {
      toast.dismiss();
      toast.error('You do not have permission to update data.', {
          autoClose: 1500,             
  });
      return;
  }
    axios.put(`${BASE_URL}faq/update/${id}`, { title, description },{
      headers: {
          'Authorization': `Bearer ${BACKENDTOKEN}`
      }
  })
      .then(response => {
        toast.dismiss();
        toast.success('FAQ updated successfully', {
          autoClose: 1500,
        });
        setTimeout(() => {
          setMessage(null);
          navigate(`/faqaction/${id}?action=view`);
        }); 
      })
      .catch(error => {
        toast.dismiss();
        toast.error('Failed to update FAQ', {
          autoClose: 1500,
        });
        setTimeout(() => {
          setMessage(null);
        }, 2000); // Clear message after 2 seconds
      });
  };

  const handleEdit = () => {
    navigate(`/faqaction/${id}?action=edit`);
  };

  const handleCancel = () => {
    navigate(`/faqaction/${id}?action=view`);
  };
  const handleDescriptionChange = (value) => {
    setDescription(value);
  };
  return (
    <div className="container-xl my-4">
      <div className="row">
        <div className="col-md-6">
          <h4>FAQ</h4>
        </div>
        <div className="col-md-6">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-end fs-3">
              <li className="breadcrumb-item"><Link to="/faq" className='fs-6'>Back</Link></li>
              <li className="breadcrumb-item"><Link to="#" className='fs-6'>ActionPage</Link></li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="col-12 mt-3">
        <div className="card mt-3">
          <div className="card-header header-underline pb-1">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="card-title">{action === 'edit' ? 'Edit Details' : 'View Details'}</h5>
            </div>
          </div>
          <div className="card-body mt-3">
            <form>
              <div className="mb-3">
                <label className='form-label'>Title:</label>
                <input
                  type="text"
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                  disabled={!isEditable}
                  className="form-control mb-2"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Description:</label>
                {isEditable ? (
                  <ReactQuill
                    theme="snow"
                    value={description}
                    onChange={handleDescriptionChange}
                    modules={{
                      toolbar: [
                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                        [{ 'size': [] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        ['link', 'image'],
                        ['clean']
                      ]
                    }}
                    style={{ height: '300px', borderRadius: '10px' }}
                    className="react-quill-editor mb-3"
                  />
                ) : (
                  <div
                    className="form-control mb-2"
                    dangerouslySetInnerHTML={{ __html: description }}
                    style={{ minHeight: '130px', backgroundColor: "#f4f4f4" }}
                  />
                )}
              </div>

            </form>

          </div>
          <div className='card-footer'>
            <div className="d-flex mt-4 justify-content-end align-items-center">
              {action === 'view' &&  rolePermissions.write === 'yes'  && (
                <button type="button" onClick={handleEdit} className="btn btn-primary mb-2">Edit</button>
              )}
              {isEditable && (
                <button type="button" onClick={handleUpdate} className="btn btn-primary mx-3">Update</button>
              )}
              {action === 'edit' && (
                <button type="button" onClick={handleCancel} className=' btn btn-label-secondary'>Cancel</button>
              )}
            </div>
            {message && <div className="message text-primary">{message}</div>}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ActionFAQ;
