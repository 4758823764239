// src/pages/Login.js
import React, { useState ,useEffect} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../Redux/Slice/authSlice'; // Adjust the path as necessary
import { fetchUserPermissions } from '../Redux/Slice/roleSlice'; // Adjust the path as necessary
import { Helmet } from 'react-helmet';
import { BASE_URL ,BACKENDTOKEN} from '../Constant/Constant';

const validationSchema = Yup.object({
    email: Yup.string()
        .email('Invalid email address')
        .required('Please enter your email.'),
    password: Yup.string()
        .required('Please enter your password.')
});

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [userId,SetUserId]=useState();


    useEffect(() => {
        const token = localStorage.getItem('BackendToken');
        if (token) {
            navigate('/dashboard'); // Redirect to login if not authenticated 
        }
    }, [navigate]);

    const handleLogin = async (values, { setSubmitting }) => {
        const apiUrl = `${BASE_URL}user/login`;

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            });

            // if (!response.ok) {
            //     const errorData = await response.json();
            //     console.log("Error response:", errorData);
            //     throw new Error(errorData.message || 'Login failed');
            // }

            const data = await response.json();
            console.log("data",data)


            localStorage.setItem('BackendToken', data.token); // Store the token in localStorage
           
            

            // Dispatch login success action
            dispatch(loginSuccess({ token: data.token, userId: data.userId }));

            SetUserId(data.userId)

            // Fetch user permissions
            const permissionsResponse = await fetch(`${BASE_URL}user/permission/${data.userId}`, {
                method: 'GET',
               
            });

            if (!permissionsResponse.ok) {
                throw new Error('Failed to fetch user permissions');
            }

            const permissionsData = await permissionsResponse.json();
            dispatch(fetchUserPermissions(permissionsData)); // Dispatch action to update roles

            navigate('/dashboard');
        } catch (error) {
            console.log("Caught error:", error.message);
            toast.dismiss(); // Dismiss any existing toasts
            toast.error(error.message); // Show the new toast
           
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className="authentication-wrapper authentication-cover authentication-bg" style={{ backgroundColor: 'white' }}>
            <Helmet>
                <title>Login </title>
                <meta name="description" content="Log in to your account to access personalized features and services." />
            </Helmet>
            <div className="authentication-inner row">
                <div className="d-none d-lg-flex col-lg-7 p-0">
                    <div className="auth-cover-bg d-flex justify-content-center align-items-center" style={{ backgroundColor: '#f8f7fa' }}>
                        <img src="./assets/img/illustrations/auth-login-illustration-light.png" alt="auth-login-cover" className="img-fluid my-5 auth-illustration" />
                        <img src="./assets/img/illustrations/bg-shape-image-light.png" alt="auth-login-cover" className="platform-bg" />
                    </div>
                </div>
                <div className="d-flex col-12 col-lg-5 align-items-center p-sm-5 p-4">
                    <div className="w-px-400 mx-auto">
                        <div className="app-brand mb-3">
                        <Link className="d-flex" href="/">
                            <img
                                alt="Aargauertaxi"
                                src="/assets/img/template/logo-old.png"
                                style={{ height: "45px", width: "230px" ,marginLeft:"-10px"}}
                            />
                        </Link>
                        </div>
                        <h3 className=" mb-1">Welcome to Aargauertaxi</h3>
                        <p className="mb-4">Please sign-in to your account and start the adventure</p>
                        <Formik
                            initialValues={{ email: '', password: '' }}
                            validationSchema={validationSchema}
                            onSubmit={handleLogin}
                            validateOnChange={true}
                            validateOnBlur={true}
                        >
                            {({ isSubmitting, errors, touched }) => (
                                <Form className="mb-3 needs-validation" noValidate>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <Field
                                            type="email"
                                            name="email"
                                            className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
                                            placeholder="Enter your email"
                                        />
                                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="mb-3 form-password-toggle">
                                        <div className="d-flex justify-content-between">
                                            <label className="form-label" htmlFor="password">Password</label>
                                            <Link to="../forgotpassword">
                                                <small>Forgot Password?</small>
                                            </Link>
                                        </div>
                                        <div className="input-group input-group-merge">
                                            <Field
                                                as="input"
                                                name="password"
                                                type={isPasswordVisible ? 'text' : 'password'}
                                                className={`form-control ${touched.password && errors.password ? 'is-invalid' : ''}`}
                                                placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                            />
                                            <span className="input-group-text cursor-pointer" onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                                                <i className={isPasswordVisible ? "ti ti-eye" : "ti ti-eye-off"}></i>
                                            </span>
                                        </div>
                                        <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                    </div>
                                    <button className="btn btn-primary d-grid w-100" type="submit" disabled={isSubmitting}>
                                        {isSubmitting ? 'Loading...' : 'Sign in'}
                                    </button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default Login;
