import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL, BACKENDTOKEN } from '../../Constant/Constant'
import { useSelector } from 'react-redux';

const ActionMetaTags = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const { id } = useParams();
    const action = queryParams.get('action');
    const navigate = useNavigate();

    const [meta_title, setmeta_title] = useState('');
    const [meta_description, setMetaDescription] = useState('');
    const [meta_keywords, setMetaKeywords] = useState('');
    const [category, setCategory] = useState('');
    const [isEditable, setIsEditable] = useState(false);

    const [message, setMessage] = useState(null);

    const permissions = useSelector(state => state.roles.permissions.user_modules);
    const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });

    useEffect(() => {
        if (permissions) {
            try {
                const modules = JSON.parse(permissions);
                const MetaTags = modules.find(module => module.rolename === 'MetaTags');
                if (MetaTags) {
                    setRolePermissions(MetaTags.permissions);
                }
            } catch (error) {
                console.error('Failed to parse permissions:', error);
            }
        }
    }, [permissions]);

    useEffect(() => {
        axios.get(`${BASE_URL}metatags/get/${id}`, {
            headers: {
                'Authorization': `Bearer ${BACKENDTOKEN}`
            }
        })
            .then(response => {
                const data = response.data;
                setmeta_title(data.meta_title);
                setMetaDescription(data.meta_description);
                setMetaKeywords(data.meta_keywords);
                setCategory(data.category)
            })
            .catch(error => {
                toast.error('Error fetching MetaTags', {
                    autoClose: 1500,
                });
            });
    }, [id]);

    useEffect(() => {
        setIsEditable(action === 'edit');
    }, [action]);

    const handleUpdate = () => {
        if (rolePermissions.write === 'no') {
            toast.dismiss();
            toast.error('You do not have permission to update data.', {
                autoClose: 1500,
            });
            return;
        }
        axios.put(`${BASE_URL}metatags/update/${id}`, { meta_title, meta_description, meta_keywords, category }, {
            headers: {
                'Authorization': `Bearer ${BACKENDTOKEN}`
            }
        })
            .then(response => {
                toast.success('MetaTags updated successfully', {
                    autoClose: 1500,
                });
                setTimeout(() => {
                    setMessage(null);
                    navigate(`/action_metatags/${id}?action=view`);
                });
            })
            .catch(error => {
                toast.error('Failed to update  MetaTags', {
                    autoClose: 1500,
                });
                setTimeout(() => {
                    setMessage(null);
                }, 2000);
            });
    };

    const handleEdit = () => {
        navigate(`/action_metatags/${id}?action=edit`);
    };

    const handleCancel = () => {
        navigate(`/action_metatags/${id}?action=view`);
    };
    const categories = [
        { value: '', label: 'Select Category' },
        { value: 'home', label: 'Home' },
        { value: 'about', label: 'About' },
        { value: 'ourfleet', label: 'Our Fleet' },
        { value: 'services', label: 'Services' },
        { value: 'contact', label: 'Contact' }
    ];

    return (
        <div className="container-xl my-4">

            <div className="row">
                <div className="col-md-6">
                    <h4>MetaTags</h4>
                </div>
                <div className="col-md-6">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end fs-3">
                            <li className="breadcrumb-item"><Link to="../metatags" className='fs-6'>Back</Link></li>
                            <li className="breadcrumb-item"><Link to="#" className='fs-6'>ActionPage</Link></li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="col-12 mt-3">
                <div className="card mt-3">
                    <div className="card-header header-underline pb-1">
                        <div className="d-flex justify-content-between align-items-center">
                            <h5 className="card-legal_meta_title">{action === 'edit' ? 'Edit Details' : 'View Details'}</h5>
                        </div>
                    </div>
                    <div className="card-body mt-3">
                        <form className="row g-3">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="col-sm col-form-label">Category</label>
                                    <select
                                        value={category}
                                        onChange={(e) => setCategory(e.target.value)}
                                        disabled={!isEditable}
                                        className="form-select mb-2"
                                    >
                                        {categories.map(cat => (
                                            <option key={cat.value} value={cat.value}>{cat.label}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="meta_description" className="col-sm col-form-label">Meta Description</label>
                                    <textarea
                                        id="meta_description"
                                        rows="3"
                                        value={meta_description}
                                        onChange={(e) => setMetaDescription(e.target.value)}
                                        disabled={!isEditable}
                                        className="form-control mb-2"
                                    />
                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="col-sm col-form-label">Meta Title</label>
                                    <input
                                        type="text"
                                        value={meta_title}
                                        onChange={(e) => setmeta_title(e.target.value)}
                                        disabled={!isEditable}
                                        className="form-control mb-2"
                                    />
                                </div>


                                <div className="mb-3">
                                    <label htmlFor="meta_keywords" className="col-sm col-form-label">Meta Keywords</label>
                                    <textarea
                                        id="meta_keywords"
                                        rows="3"
                                        value={meta_keywords}
                                        onChange={(e) => setMetaKeywords(e.target.value)}
                                        disabled={!isEditable}
                                        className="form-control mb-2"
                                    />
                                </div>
                            </div>
                            {isEditable ? (
                                <div className="col-12 d-flex justify-content-end align-items-center">
                                    <button type="button" onClick={handleCancel} className="btn btn-label-secondary me-3">
                                        Cancel
                                    </button>
                                    <button type="button" onClick={handleUpdate} className="btn btn-primary">
                                        Update
                                    </button>

                                </div>
                            ) : (rolePermissions.write === 'yes' && (
                                <div className="col-12 d-flex justify-content-end align-items-center">
                                    <button type="button" onClick={handleEdit} className="btn btn-primary">
                                        Edit
                                    </button>
                                </div>
                            ))}
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActionMetaTags;
