import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../../Constant/Constant';
import 'react-toastify/dist/ReactToastify.css';

const Modules = ({ onPermissionsChange, reset }) => {
  const [permissions, setPermissions] = useState([]);
  const [permissionsState, setPermissionsState] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await axios.get(`${BASE_URL}user/permission`);
        const data = response.data[0]; // Assuming the data is an array and taking the first item

        const modules = JSON.parse(data.user_modules); // Parse the user_modules JSON string
        const initialPermissions = modules.reduce((acc, moduleName, index) => {
          acc[index + 1] = { moduleName, read: false, write: false };
          return acc;
        }, {});

        setPermissions(modules.map((moduleName, index) => ({
          id: index + 1,
          moduleName
        })));
        setPermissionsState(initialPermissions);
        setLoading(false);
      } catch (error) {
        toast.error('Failed to load permissions. Please try again later.');
        setLoading(false);
      }
    };

    fetchPermissions();
  }, []);

  useEffect(() => {
    if (reset) {
      const resetPermissions = permissions.reduce((acc, perm) => {
        acc[perm.id] = { moduleName: perm.moduleName, read: false, write: false };
        return acc;
      }, {});
      setPermissionsState(resetPermissions);
      onPermissionsChange(convertPermissionsToModuleNameFormat(resetPermissions));
    }
  }, [reset, permissions]);

  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    const updatedPermissions = permissions.reduce((acc, perm) => {
      acc[perm.id] = { moduleName: perm.moduleName, read: checked, write: checked };
      return acc;
    }, {});
    setPermissionsState(updatedPermissions);
    onPermissionsChange(convertPermissionsToModuleNameFormat(updatedPermissions));
  };

  const handlePermissionChange = (e) => {
    const { id, checked } = e.target;
    const [permId, permType] = id.split('-');

    setPermissionsState((prevState) => {
      const updatedPermissions = {
        ...prevState,
        [permId]: {
          ...prevState[permId],
          [permType]: checked
        }
      };
      onPermissionsChange(convertPermissionsToModuleNameFormat(updatedPermissions));
      return updatedPermissions;
    });
  };

  const isSelectAllChecked = permissions.length > 0 && permissions.every(
    (perm) => permissionsState[perm.id]?.read && permissionsState[perm.id]?.write
  );

  const convertPermissionsToModuleNameFormat = (permissions) => {
    return Object.entries(permissions).map(([id, { moduleName, read, write }]) => ({
      rolename: moduleName,
      permissions: {
        read: read ? "yes" : "no",
        write: write ? "yes" : "no"
      }
    }));
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div className="col-12">
      <h5>Modules *</h5>
      <div className="table-responsive">
        <table className="table table-flush-spacing">
          <tbody className="border-1 rounded">
            <tr>
              <td className="text-nowrap fw-medium px-4">
                Modules
              </td>
              <td>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="selectAll"
                    checked={isSelectAllChecked}
                    onChange={handleSelectAll}
                  />
                  <label className="form-check-label" htmlFor="selectAll">
                    Select All
                  </label>
                </div>
              </td>
            </tr>

            {permissions.map((perm) => (
              <tr key={perm.id}>
                <td className="text-nowrap fw-medium px-4">{perm.moduleName}</td>
                <td>
                  <div className="d-flex">
                    <div className="form-check me-3 me-lg-5">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={`${perm.id}-read`}
                        checked={permissionsState[perm.id]?.read || false}
                        onChange={handlePermissionChange}
                      />
                      <label className="form-check-label" htmlFor={`${perm.id}-read`}>
                        Read
                      </label>
                    </div>
                    <div className="form-check me-3 me-lg-5">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={`${perm.id}-write`}
                        checked={permissionsState[perm.id]?.write || false}
                        onChange={handlePermissionChange}
                      />
                      <label className="form-check-label" htmlFor={`${perm.id}-write`}>
                        Write
                      </label>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Modules;
