import React from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from '../Constant/Constant'
import { Helmet } from 'react-helmet';

// Validation schema using Yup
const validationSchema = Yup.object({
    email: Yup.string()
        .email('Invalid email address')
        .required('Please enter your email.')
});

const ForgotPassword = () => {
    const [loading, setLoading] = React.useState(false);

    const handleSubmit = async (values, { setSubmitting }) => {
        setLoading(true);

        try {
            const response = await fetch(`${BASE_URL}user/forgot-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            });

            const data = await response.json();

            if (response.ok) {
                toast.dismiss();
                toast.success('Check your email for password reset instructions');
            } else {
                toast.dismiss();
                toast.error(data.message || 'Error sending reset email');
            }
        } catch (error) {
            toast.dismiss();
            toast.error('Error sending reset email');
        } finally {
            setLoading(false);
            setSubmitting(false);
        }
    };

    return (
        <div className="authentication-wrapper authentication-cover authentication-bg" style={{ backgroundColor: 'white' }}>
            <Helmet>
                <title>Forgot Password </title>
                <meta name="description" content="Reset your password and regain access to your account by receiving a password reset link." />
            </Helmet>
            <ToastContainer position="top-right" autoClose={5000} />
            <div className="authentication-inner row">
                <div className="d-none d-lg-flex col-lg-7 p-0">
                    <div className="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
                        <img
                            src="././assets/img/illustrations/auth-forgot-password-illustration-light.png"
                            alt="auth-forgot-password-cover"
                            className="img-fluid my-5 auth-illustration"
                            data-app-light-img="illustrations/auth-forgot-password-illustration-light.png"
                            data-app-dark-img="illustrations/auth-forgot-password-illustration-dark.png"
                        />
                        <img
                            src="././assets/img/illustrations/bg-shape-image-light.png"
                            alt="auth-forgot-password-cover"
                            className="platform-bg"
                            data-app-light-img="illustrations/bg-shape-image-light.png"
                            data-app-dark-img="illustrations/auth-forgot-password-illustration-dark.png"
                        />
                    </div>
                </div>
                <div className="d-flex col-12 col-lg-5 align-items-center p-sm-5 p-4">
                    <div className="w-px-400 mx-auto">
                        <div className="app-brand mb-4">
                            <Link to="index.html" className="app-brand-link gap-2">
                                <span className="app-brand-logo demo">
                                    <svg width="150" height="19" viewBox="0 0 150 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        {/* SVG paths */}
                                    </svg>
                                </span>
                            </Link>
                        </div>
                        <h3 className="mb-1">Forgot Password? 🔒</h3>
                        <p className="mb-4">Enter your email and we'll send you instructions to reset your password</p>
                        <Formik
                            initialValues={{ email: '' }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ isSubmitting }) => (
                                <Form className="mb-3 needs-validation" noValidate>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <Field
                                            type="email"
                                            name="email"
                                            className="form-control"
                                            placeholder="Enter your email"
                                        />
                                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                    </div>
                                    <button type="submit" disabled={isSubmitting || loading} className="btn btn-primary d-grid w-100">
                                        {loading ? 'Sending...' : 'Send Reset Link'}
                                    </button>
                                    <div className="text-center mt-3">
                                        <Link to="../login" className="d-flex align-items-center justify-content-center">
                                            <i className="ti ti-chevron-left scaleX-n1-rtl"></i>
                                            Back to login
                                        </Link>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
