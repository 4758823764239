import React, { useState ,useRef} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {BASE_URL,BACKENDTOKEN} from '../../../Constant/Constant'

const AddContactUs = () => {
    const [loading, setLoading] = useState(false);
    const fileInputRef = useRef(null); // Create a ref for the file input
    const initialValues = {
        branch_name: '',
        branch_type: '',
        street: '',
        number: '',
        image: null,
        postal_code: '',
        city: '',
        state: '',
        working_hours: '',
        email: '',
        mobile: ''
    };

    const validationSchema = Yup.object().shape({
        branch_name: Yup.string().required('Branch Name is required'),
        branch_type: Yup.string().required('Branch Type is required'),
        street: Yup.string().required('Street is required'),
        number: Yup.number().typeError("Number must be a number").required('Number is required'),
        postal_code: Yup.string().required('Postal Code is required'),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        working_hours: Yup.string().required('Working Hours are required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        mobile: Yup.string().required('Mobile is required'), // Changed to string
        image: Yup.mixed().nullable().test('fileType', 'Unsupported file type. Only JPEG, PNG, and GIF are allowed.', value => {
            // Check if the file is an image
            return !value || (value && ['image/jpeg', 'image/png', 'image/gif'].includes(value.type));
          })
    });

    const handleSubmit = async (values, { resetForm, setFieldValue }) => {
        setLoading(true);
        try {
            const formData = new FormData();
            // Append all the fields to formData
            Object.keys(values).forEach(key => {
                if (key === 'image' && values[key]) {
                    formData.append(key, values[key]);
                } else {
                    formData.append(key, values[key]);
                }
            });

            // Retrieve language_id from localStorage
            const language_id = localStorage.getItem('selectedLanguageId');
            formData.append('language_id', language_id);

            const response = await fetch(`${BASE_URL}contact/add`, {
                headers: {
                    'Authorization': `Bearer ${BACKENDTOKEN}`
                },
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Error adding contact');
            }

            const result = await response.json();
            toast.dismiss();
            toast.success(result.message ||"Add Successfully", {
                autoClose: 1500,             
});
            resetForm();
            setFieldValue('image', null); // Clear the image field manually
            if (fileInputRef.current) {
                fileInputRef.current.value = ''; // Clear the file input field using the ref
            }

        } catch (error) {
            console.error('Error:', error);
            toast.dismiss();
            toast.error('Failed to add contact: ' + error.message, {
                autoClose: 1500,             
});
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container-xl my-4">
            <ToastContainer />
            <div className="row">
                <div className="col-md-6">
                    <h4>ContactUs</h4>
                </div>
                <div className="col-md-6">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end fs-3">
                            <li className="breadcrumb-item"><Link to="/contactus" className='fs-6'>Back</Link></li>
                            <li className="breadcrumb-item"><Link to="#" className='fs-6'>Add</Link></li>
                        </ol>
                    </nav>
                </div>
            </div>

            <div className="col-10 mt-3">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {formik => (
                        <Form className="card">
                            <div className="card-header" style={{ borderBottom: '1px solid #ccc', marginBottom: '1rem', paddingBottom: '1rem' }}>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <h5 className="card-title me-3">Add</h5>
                                    <div className='col-2'>
                                        <Field
                                            as="select"
                                            name="branch_type"
                                            className={`form-select ${formik.errors.branch_type && formik.touched.branch_type ? 'is-invalid' : ''}`}
                                        >
                                            <option value="">Branch Type</option>
                                            <option value="MainBranch">Main Branch</option>
                                            <option value="SubBranch">Sub Branch</option>
                                        </Field>
                                        <ErrorMessage name="branch_type" component="div" className="invalid-feedback" />
                                    </div>
                                </div>
                            </div>

                            <div className="card-body">
                                <div className="row g-2 mb-3">
                                    <div className="col-sm-6">
                                        <label className="col-form-label" htmlFor="branch_name">Branch Name</label>
                                        <Field
                                            type="text"
                                            className={`form-control ${formik.errors.branch_name && formik.touched.branch_name ? 'is-invalid' : ''}`}
                                            id="branch_name"
                                            name="branch_name"
                                        />
                                        <ErrorMessage name="branch_name" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="col-sm-6">
                                        <label className="col-form-label" htmlFor="image">Image</label>
                                        <input
                                            type="file"
                                            accept="image/jpeg, image/png, image/gif"
                                            ref={fileInputRef} // Attach the ref to the file input
                                            className={`form-control ${formik.errors.image && formik.touched.image ? 'is-invalid' : ''}`}
                                            id="image"
                                            name="image"
                                            onChange={(event) => formik.setFieldValue('image', event.currentTarget.files[0])}
                                        />
                                        <ErrorMessage name="image" component="div" className="invalid-feedback" />
                                    </div>
                                </div>
                                <div className="row g-2 mb-3">
                                    <div className="col-sm-3">
                                        <label className="col-form-label" htmlFor="street">Street Name</label>
                                        <Field
                                            type="text"
                                            className={`form-control ${formik.errors.street && formik.touched.street ? 'is-invalid' : ''}`}
                                            id="street"
                                            name="street"
                                        />
                                        <ErrorMessage name="street" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="col-sm-3">
                                        <label className="col-form-label" htmlFor="number">House Number</label>
                                        <Field
                                            type="number"
                                            className={`form-control ${formik.errors.number && formik.touched.number ? 'is-invalid' : ''}`}
                                            id="number"
                                            name="number"
                                        />
                                        <ErrorMessage name="number" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="col-sm-3">
                                        <label className="col-form-label" htmlFor="postal_code">Postal Code</label>
                                        <Field
                                            type="text"
                                            className={`form-control ${formik.errors.postal_code && formik.touched.postal_code ? 'is-invalid' : ''}`}
                                            id="postal_code"
                                            name="postal_code"
                                        />
                                        <ErrorMessage name="postal_code" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="col-sm-3">
                                        <label className="col-form-label" htmlFor="city">City</label>
                                        <Field
                                            type="text"
                                            className={`form-control ${formik.errors.city && formik.touched.city ? 'is-invalid' : ''}`}
                                            id="city"
                                            name="city"
                                        />
                                        <ErrorMessage name="city" component="div" className="invalid-feedback" />
                                    </div>
                                </div>

                                <div className="row g-2 mb-3">
                                    <div className="col-sm-6">
                                        <label className="col-form-label" htmlFor="state">State</label>
                                        <Field
                                            type="text"
                                            className={`form-control ${formik.errors.state && formik.touched.state ? 'is-invalid' : ''}`}
                                            id="state"
                                            name="state"
                                        />
                                        <ErrorMessage name="state" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="col-sm-6">
                                        <label className="col-form-label" htmlFor="working_hours">Working Hours</label>
                                        <Field
                                            type="text"
                                            className={`form-control ${formik.errors.working_hours && formik.touched.working_hours ? 'is-invalid' : ''}`}
                                            id="working_hours"
                                            name="working_hours"
                                        />
                                        <ErrorMessage name="working_hours" component="div" className="invalid-feedback" />
                                    </div>
                                </div>

                                <div className="row g-2 mb-3">
                                    <div className="col-sm-6">
                                        <label className="col-form-label" htmlFor="email">Email</label>
                                        <Field
                                            type="email"
                                            className={`form-control ${formik.errors.email && formik.touched.email ? 'is-invalid' : ''}`}
                                            id="email"
                                            name="email"
                                        />
                                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="col-sm-6">
                                        <label className="col-form-label" htmlFor="mobile">Mobile</label>
                                        <PhoneInput
                                            className={` ${formik.errors.mobile && formik.touched.mobile ? 'is-invalid' : ''}`}
                                            id="mobile"
                                            name="mobile"
                                            country={'ch'} // Set default country code
                                            value={formik.values.mobile} // Corrected
                                            onChange={(value) => formik.setFieldValue('mobile', value)} // Corrected
                                            inputStyle={{
                                                width: "100%",
                                                height: "40px",
                                                paddingLeft: "44px",
                                                border: "1px solid #ccc",
                                                borderRadius: "5px",
                                                fontSize: "16px",
                                            }}
                                        />
                                        <ErrorMessage name="mobile" component="div" className="invalid-feedback" />
                                    </div>
                                </div>
                            </div>

                            <div className="card-footer text-end" style={{ borderTop: '1px solid #ccc', marginTop: '1rem', paddingTop: '1rem' }}>
                                <button type="submit" className="btn btn-primary" disabled={loading}>
                                    {loading ? 'Adding...' : 'Add'}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default AddContactUs;
