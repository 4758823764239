import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BASE_URL,BACKENDTOKEN} from '../../../Constant/Constant'

import { useSelector } from 'react-redux';


const ActionCarCategory = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { id } = useParams();
  const action = queryParams.get('action');
  const navigate = useNavigate();

  const [name, setname] = useState('');
 
  const [image, setImage] = useState('');

  const [isEditable, setIsEditable] = useState(false);
  const [message, setMessage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [file, setFile] = useState(null);
  const [originalImage, setOriginalImage] = useState('');
    
const permissions = useSelector(state => state.roles.permissions.user_modules);
const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });

useEffect(() => {
    if (permissions) {
        try {
            const modules = JSON.parse(permissions);
            const carcatgory = modules.find(module => module.rolename === 'carcatgory');
            if (carcatgory) {
                setRolePermissions(carcatgory.permissions);
            }
        } catch (error) {
            console.error('Failed to parse permissions:', error);
        }
    }
}, [permissions]);

  useEffect(() => {
    axios.get(`${BASE_URL}carcategory/get/${id}`, {
      headers: {
          'Authorization': `Bearer ${BACKENDTOKEN}`
      }
  })
      .then(response => {
        const data = response.data;
      
        setname(data.name);
       
        setImage(data.image);
  
        setPreviewImage(`${BASE_URL}uploads/${data.image}`);
        setOriginalImage(data.image);
        setFile(null);
      })
      .catch(error => {
        console.error('Error fetching car category:', error);
      });
  }, [id]);

  useEffect(() => {
    setIsEditable(action === 'edit');
  }, [action]);

  const handleUpdate = async () => {
    try {
    //   if (rolePermissions.write === 'no') {
    //     toast.dismiss();
    //     toast.error('You do not have permission to update data.', {
    //         autoClose: 1500,             
    // });
    //     return;
    // }
      const formData = new FormData();
      formData.append('name', name);

      if (file) {
        formData.append('new_image', file);
      } else {
        formData.append('image', originalImage);
      }

      await axios.put(`${BASE_URL}carcategory/update/${id}`, formData, {
        headers: {
          'Authorization': `Bearer ${BACKENDTOKEN}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      toast.dismiss();
      toast.success('Car category updated successfully', {
        autoClose: 1500,             
});
      setTimeout(() => {
        setMessage(null);
        navigate(`/ActionCarCategory/${id}?action=view`);
      });
    } catch (error) {
      console.error('Error updating car category:', error);
      toast.dismiss();
      toast.error('Failed to update car category', {
        autoClose: 1500,             
});
      setTimeout(() => {
        setMessage(null);
      }, 2000);
    }
  };

  const handleEdit = () => {
    navigate(`/ActionCarCategory/${id}?action=edit`);
  };

  const handleCancel = () => {
    navigate(`/ActionCarCategory/${id}?action=view`);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setPreviewImage(URL.createObjectURL(selectedFile));
    }
  };
 


  return (
    <div className="container-xl my-4">

      <div className="row">
        <div className="col-md-6">
          <h4>Car Category </h4>
        </div>
        <div className="col-md-6">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-end fs-3">
              <li className="breadcrumb-item"><Link to="../carcategory" className='fs-6'>Back</Link></li>
              <li className="breadcrumb-item"><Link to="#" className='fs-6'>ActionPage</Link></li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="col-8 mt-3">
        <div className="card mt-3">
          <div className="card-header header-underline pb-1">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="card-name">{isEditable ? 'Edit Details' : 'View Details'}</h5>
            </div>
          </div>
          <div className="card-body mt-3">
            <form >
              
                <div className="mb-3">
                  <label className="col-sm col-form-label">Name</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                    disabled={!isEditable}
                    className="form-control mb-2"
                  />
                </div>
               
             
                <div className="mb-3">
                  <label className="col-sm col-form-label" htmlFor="image">Image</label>
                  <div className="mb-3">
                    {previewImage && (
                      <img
                        src={previewImage}
                        alt="Car Image Preview"
                        className="img-fluid mb-2"
                        style={{ maxHeight: '306px', width: '100%' }}
                      />
                    )}
                  </div>
                  {isEditable && (
                    <input
                      type="file"
                      id="image"
                      onChange={handleFileChange}
                      className="form-control mb-2"
                    />
                  )}
                </div>
             
             
           
              {isEditable ? (
                <div className="col-12 d-flex justify-content-end align-items-center">
                  <button type="button" onClick={handleUpdate} className="btn btn-primary">
                   Update
                  </button>
                  <button type="button" onClick={handleCancel} className="btn btn-label-secondary ms-2">
                    Cancel
                  </button>
                </div>
              ) : ( rolePermissions.write === 'no' && (
                <div className="col-12 d-flex justify-content-end align-items-center">
                  <button type="button" onClick={handleEdit} className="btn btn-primary">
                    Edit
                  </button>
                </div>
             ) )}
   
            </form>
            {message && <p className="mt-3">{message}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionCarCategory;
