import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from '../../../Constant/Constant';


const AddLegalContents = () => {
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);

    // Function to get language_id from localStorage
    const getLanguageId = () => {
        return localStorage.getItem('selectedLanguageId');
    };

    const handleDescriptionChange = (value) => {
        setDescription(value);
    };

    const initialValues = {
        legal_title: '',
        legal_type: '',
    };

    const DisplayingErrorMessagesSchema = Yup.object().shape({
        legal_title: Yup.string().required('Legal Title is required'),
        legal_type: Yup.string().required('Select Legal Type is required'),
    });

    const handleSubmit = async (values, { resetForm }) => {
        setLoading(true);
        const BackendToken = localStorage.getItem('BackendToken');
    
        try {
            const response = await fetch(`${BASE_URL}legalcontent/add`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${BackendToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    legal_title: values.legal_title,
                    legal_type: values.legal_type,
                    description: description,
                    language_id: getLanguageId(),
                }),
            });
    
            const data = await response.json();
    
            // Handle responses based on the status code
            if (response.status === 201) {
                toast.dismiss();
                toast.success(data.message, { autoClose: 1500 });
                resetForm();
                setDescription('');
            } else {
                toast.dismiss();
                toast.error(data.message || 'Failed to add legal content.', { autoClose: 1500 });
            }
        } catch (error) {
            toast.dismiss();
            toast.error('An error occurred while adding legal content.', { autoClose: 1500 });
        } finally {
            setLoading(false);
        }
    };
    
    

    return (
        <>
            <div className="container-xl my-4">
                <div className="row">
                    <div className="col-md-6">
                        <h4><span className="text-muted fw-light"> </span> Legal Contents</h4>
                    </div>
                    <div className="col-md-6">
                        <div className="breadcumbtext">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-end">
                                    <li className="breadcrumb-item">
                                        <Link className="footer-link fs-6" to="../legalcontents">Back</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link className="footer-link fs-6" to="#">Add</Link>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="row row-cards mt-2">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Add</h4>
                            </div>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={DisplayingErrorMessagesSchema}
                                onSubmit={handleSubmit}
                            >
                                {formik => (
                                    <Form>
                                        <div className="card-body">

                                            <div className="modal-body">
                                                <div className="row g-3">
                                                    <div className="col mb-0">
                                                        <label htmlFor="legal_title" className="form-label">Legal Title</label>
                                                        <Field
                                                            id="legal_title"
                                                            name="legal_title"
                                                            type="text"
                                                            className={`form-control ${formik.errors.legal_title && formik.touched.legal_title ? 'is-invalid' : ''}`}
                                                        />
                                                        {formik.errors.legal_title && formik.touched.legal_title && (
                                                            <div className="invalid-feedback">{formik.errors.legal_title}</div>
                                                        )}
                                                    </div>
                                                    <div className="col mb-0">
                                                        <label htmlFor="legal_type" className="form-label">Select Legal Type</label>
                                                        <Field
                                                            as="select"
                                                            id="legal_type"
                                                            name="legal_type"
                                                            className={`form-select ${formik.errors.legal_type && formik.touched.legal_type ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Select</option>
                                                            <option value="Privacy_policy">Privacy Policy</option>
                                                            <option value="Terms_conditions">Terms & Conditions</option>
                                                            <option value="Licenses">Licenses</option>
                                                            <option value="Refund">Refund</option>
                                                        </Field>
                                                        {formik.errors.legal_type && formik.touched.legal_type && (
                                                            <div className="invalid-feedback">{formik.errors.legal_type}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col">
                                                        <label htmlFor="description" className="form-label">Description</label>
                                                        <ReactQuill
                                                            theme="snow"
                                                            value={description}
                                                            onChange={handleDescriptionChange}
                                                            modules={{
                                                                toolbar: [
                                                                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                                    [{ 'size': [] }],
                                                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                                    ['link', 'image'],
                                                                    ['clean']
                                                                ]
                                                            }}

                                                            className="react-quill-editor mb-3"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="card-footer text-end mt-3 mb-0">
                                            <button type="submit" className="btn btn-primary" disabled={loading}>
                                                {loading ? 'Adding...' : 'Add'}
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddLegalContents;
