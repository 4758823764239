import React, { useState, useEffect,useRef } from 'react';
import { IoAddCircleOutline } from 'react-icons/io5';
import { MdOutlineCancel } from 'react-icons/md';
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BASE_URL,BACKENDTOKEN} from '../../../Constant/Constant'

const AddCar = () => {
    const [fields, setFields] = useState([{ id: 1, text: '' }]);
    const fileInputRef = useRef(null);
    const [carCategories, setCarCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [languageId, setLanguageId] = useState('');
    const [formValues, setFormValues] = useState({
        title: '',
        image: null,
        short_description: '',
        preview_image: null,
        Language: '',
        seats: '',
        luggage: '',
        start_price: '',
        day_price: '',
        night_price: '',
        discount: '',
        points: [{ id: 1, text: '' }],
        description: '',
       carcategory: ''
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const storedLanguageId = localStorage.getItem('selectedLanguageId');
        if (storedLanguageId) {
            setLanguageId(storedLanguageId);
        }
    }, []);

    useEffect(() => {
        setFormValues(prevValues => ({
            ...prevValues,
            Language: languageId
        }));
    }, [languageId]);

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        if (type === 'file') {
            setFormValues(prevValues => ({
                ...prevValues,
                [name]: files[0]
            }));
        } else {
            setFormValues(prevValues => ({
                ...prevValues,
                [name]: value
            }));
        }
    };
    

    const handleQuillChange = (value) => {
        setFormValues(prevValues => ({
            ...prevValues,
            description: value
        }));
    };

    const handleAddField = () => {
        if (fields.length >= 6) {
            toast.dismiss();
            toast.warn('Maximum of 6 points allowed');
            return;
        }
        const newId = fields.length ? fields[fields.length - 1].id + 1 : 1;
        const newField = { id: newId, text: '' };
        setFields(prevFields => [...prevFields, newField]);
        setFormValues(prevValues => ({
            ...prevValues,
            points: [...prevValues.points, newField] // Ensure points is an array
        }));
    };

    const handleDeleteField = (id) => {
        if (fields.length <= 1) return; // Prevent removing the last field

        const updatedFields = fields.filter(field => field.id !== id);
        setFields(updatedFields);
        setFormValues(prevValues => ({
            ...prevValues,
            points: prevValues.points.filter(point => point.id !== id) // Ensure points is an array
        }));
    };

    const handlePointChange = (id, text) => {
        const updatedpoints = formValues.points.map(point =>
            point.id === id ? { ...point, text } : point
        );
        setFormValues(prevValues => ({
            ...prevValues,
            points: updatedpoints
        }));
    };
    const validate = () => {
        const errors = {};
        if (!formValues.title) errors.title = 'title is required';
        // Check if image is provided
        if (!formValues.image) {
            errors.image = 'image is required';
        } else if (formValues.image && !['image/jpeg', 'image/png', 'image/gif'].includes(formValues.image.type)) {
            errors.image = 'Unsupported file type. Only JPEG, PNG, and GIF are allowed.';
        }

        // Check if preview_image is provided
        if (!formValues.preview_image) {
            errors.preview_image = 'Preview image is required';
        } else if (formValues.preview_image && !['image/jpeg', 'image/png', 'image/gif'].includes(formValues.preview_image.type)) {
            errors.preview_image = 'Unsupported file type. Only JPEG, PNG, and GIF are allowed.';
        }
       
        if (!formValues.short_description) errors.short_description = 'Short description is required';
  
        if (!formValues.Language) errors.Language = 'Select Language is required';
        if (!formValues.seats || isNaN(formValues.seats) || formValues.seats <= 0) errors.seats = 'seats are required and must be a positive number';
        if (!formValues.luggage) errors.luggage = 'luggage description is required';
        if (!formValues.start_price || isNaN(formValues.start_price) || formValues.start_price <= 0) errors.start_price = 'Start Price is required and must be a positive number';
        if (!formValues.day_price || isNaN(formValues.day_price) || formValues.day_price <= 0) errors.day_price = 'Day Price is required and must be a positive number';
        if (!formValues.night_price || isNaN(formValues.night_price) || formValues.night_price <= 0) errors.night_price = 'Night Price is required and must be a positive number';
        if (!formValues.discount || isNaN(formValues.discount) || formValues.discount < 0 || formValues.discount > 100) errors.discount = 'discount is required and must be between 0 and 100';
        if (!formValues.carcategory) errors.carcategory = 'Car Category is required'; // Added here
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!validate()) return;
    
        setLoading(true);
    
        // Prepare points data to include only text values
        const pointsWithoutId = formValues.points.map(point => point.text);
    
        const formData = new FormData();
        formData.append('title', formValues.title);
        formData.append('short_description', formValues.short_description);
        formData.append('language_id', formValues.Language);
        formData.append('seats', formValues.seats);
        formData.append('luggage', formValues.luggage);
        formData.append('start_price', formValues.start_price);
        formData.append('day_price', formValues.day_price);
        formData.append('description', formValues.description);
        formData.append('night_price', formValues.night_price);
        formData.append('discount', formValues.discount);
        formData.append('points', JSON.stringify(pointsWithoutId));
        formData.append('carcategory_id', formValues.carcategory); // Added here
        if (formValues.image) formData.append('image', formValues.image);
        if (formValues.preview_image) formData.append('preview_image', formValues.preview_image);
    
        try {
            const response = await axios.post(`${BASE_URL}car/add`, formData, {
                headers: {
                     'Authorization': `Bearer ${BACKENDTOKEN}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            toast.dismiss();
            toast.success('Car added successfully');
            setFormValues({
                title: '',
                image: null,
                short_description: '',
                preview_image: null,
                Language: '',
                seats: '',
                luggage: '',
                start_price: '',
                day_price: '',
                night_price: '',
                discount: '',
                points: [{ id: 1, text: '' }],
                description: '',
                carcategory: '' // Reset here
            });
            setFields([{ id: 1, text: '' }]);
            if (fileInputRef.current) {
                fileInputRef.current.value = ''; // Clear the file input field using the ref
            }

    
        } catch (error) {
            toast.dismiss();
            // Extract and display the backend message if it exists, otherwise show a generic error message
            const errorMessage = error.response?.data?.message || 'Error submitting form';
            toast.error(errorMessage, {
                autoClose: 1500,
            });
        } finally {
            setLoading(false);
        }
    };
    

    useEffect(() => {
        const fetchCarCategories = async () => {
            try {
                const response = await axios.get(`${BASE_URL}carcategory/get`, {
                    headers: {
                         'Authorization': `Bearer ${BACKENDTOKEN}`,
                        'Content-Type': 'multipart/form-data'
                    }
                });
                setCarCategories(response.data); // Assuming the API returns an array of car categories
            } catch (error) {
                console.error('Error fetching car categories:', error);
            }
        };

        fetchCarCategories();
    }, []);

    return (
        <div className="container-xl my-4">
            <ToastContainer />
            <div className="row">
                <div className="col-md-6">
                    <h4><span className="text-muted fw-light"> </span> Cars</h4>
                </div>
                <div className="col-md-6">
                    <div className="breadcumbtext">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-end">
                                <li className="breadcrumb-item">
                                    <Link className="footer-link fs-6" to="../carlist">Back</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link className="footer-link fs-6" to="#">Add</Link>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="row row-cards">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header" style={{ borderBottom: '1px solid #ccc', marginBottom: '2rem', paddingBottom: '1rem' }}>
                            <div className='d-flex justify-content-between align-items-center'>
                                <h5 className="card-title me-3">Add</h5>
                                <div className="">
                                    
                                    <select
                                        name="carcategory"
                                        className={`form-select ${errors.carcategory ? 'is-invalid' : ''}`}
                                        value={formValues.carcategory}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Car Category</option>
                                        {carCategories.map(category => (
                                            <option key={category.id} value={category.id}>
                                                {category.name}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.carcategory && <div className="invalid-feedback">{errors.carcategory}</div>}
                                </div>

                            </div>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit} className="row g-3">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Title</label>
                                        <input
                                            name="title"
                                            type="text"
                                            className={`form-control ${errors.title ? 'is-invalid' : ''}`}
                                            value={formValues.title}
                                            onChange={handleChange}
                                        />
                                        {errors.title && <div className="invalid-feedback">{errors.title}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Image</label>
                                        <input
                                            name="image"
                                            type="file"
                                               accept="image/jpeg, image/png, image/gif"
                                            ref={fileInputRef}
                                            className={`form-control ${errors.image ? 'is-invalid' : ''}`}
                                            onChange={handleChange}
                                        />
                                        {errors.image && <div className="invalid-feedback">{errors.image}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Seats</label>
                                        <input
                                            name="seats"
                                            type="number"
                                            className={`form-control ${errors.seats ? 'is-invalid' : ''}`}
                                            value={formValues.seats}
                                            onChange={handleChange}
                                        />
                                        {errors.seats && <div className="invalid-feedback">{errors.seats}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Start Price</label>
                                        <input
                                            name="start_price"
                                            type="number"
                                            className={`form-control ${errors.start_price ? 'is-invalid' : ''}`}
                                            value={formValues.start_price}
                                            onChange={handleChange}
                                        />
                                        {errors.start_price && <div className="invalid-feedback">{errors.start_price}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Day Price</label>
                                        <input
                                            name="day_price"
                                            type="number"
                                            className={`form-control ${errors.day_price ? 'is-invalid' : ''}`}
                                            value={formValues.day_price}
                                            onChange={handleChange}
                                        />
                                        {errors.day_price && <div className="invalid-feedback">{errors.day_price}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Points</label>
                                        <div>
                                            {fields.map((field, index) => (
                                                <div key={field.id} className="input-group mb-3">
                                                    <input
                                                        className='form-control'
                                                        type="text"
                                                        value={formValues.points.find(point => point.id === field.id)?.text || ''}
                                                        onChange={(e) => handlePointChange(field.id, e.target.value)}
                                                    />
                                                    {index !== 0 && (
                                                        <button
                                                            className="btn btn-label-secondary waves-effect waves-light"
                                                            type="button"
                                                            onClick={() => handleDeleteField(field.id)}
                                                        >
                                                            <MdOutlineCancel />
                                                        </button>
                                                    )}
                                                </div>
                                            ))}
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-sm"
                                                onClick={handleAddField}
                                            >
                                                <IoAddCircleOutline style={{ fontSize: "16px" }} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Short description</label>
                                        <input
                                            name="short_description"
                                            type="text"
                                            className={`form-control ${errors.short_description ? 'is-invalid' : ''}`}
                                            value={formValues.short_description}
                                            onChange={handleChange}
                                        />
                                        {errors.short_description && <div className="invalid-feedback">{errors.short_description}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Preview image</label>
                                        <input
                                            name="preview_image"
                                            ref={fileInputRef}
                                            type="file"
                                               accept="image/jpeg, image/png, image/gif"
                                            className={`form-control ${errors.preview_image ? 'is-invalid' : ''}`}
                                            onChange={handleChange}
                                        />
                                        {errors.preview_image && <div className="invalid-feedback">{errors.preview_image}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Luggage</label>
                                        <input
                                            name="luggage"
                                            type="number"
                                            className={`form-control ${errors.luggage ? 'is-invalid' : ''}`}
                                            value={formValues.luggage}
                                            onChange={handleChange}
                                        />
                                        {errors.luggage && <div className="invalid-feedback">{errors.luggage}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Discount</label>
                                        <input
                                            name="discount"
                                            type="number"
                                            className={`form-control ${errors.discount ? 'is-invalid' : ''}`}
                                            value={formValues.discount}
                                            onChange={handleChange}
                                        />
                                        {errors.discount && <div className="invalid-feedback">{errors.discount}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Night Price</label>
                                        <input
                                            name="night_price"
                                            type="number"
                                            className={`form-control ${errors.night_price ? 'is-invalid' : ''}`}
                                            value={formValues.night_price}
                                            onChange={handleChange}
                                        />
                                        {errors.night_price && <div className="invalid-feedback">{errors.night_price}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Description</label>
                                        <ReactQuill
                                            value={formValues.description}
                                            onChange={handleQuillChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 d-flex justify-content-end align-items-center">
                                    <button
                                        type="submit"
                                        className={`btn btn-primary ${loading ? 'disabled' : ''}`}
                                        disabled={loading}
                                    >
                                        {loading ? 'Adding...' : 'Add'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddCar;
