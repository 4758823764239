
import Language from './Language'
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL, BACKENDTOKEN } from '../Constant/Constant'
import { getTokenData } from '../utils/decodeToken';


const Header = () => {
    const [userId, setUserId] = useState(null);
    const [userData, setUserData] = useState({
        image: '',
        first_name: '',
        role: ''
    });
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const { userId } = getTokenData();

                if (!userId) {
                    throw new Error('User ID or Branch ID not found');
                }
                setUserId(userId)

                const url = `${BASE_URL}user/get/${userId}`;
                const response = await axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${BACKENDTOKEN}`
                    }
                });
                setUserData({
                    image: response.data.image,
                    first_name: response.data.first_name,
                    role: response.data.role
                });
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };

        fetchUserData();
    }, []);
    return (
        <>
            <nav className="layout-navbar navbar navbar-expand-xl align-items-center navbar-dark bg-dark" id="layout-navbar">
                <div className="container-xxl">
                    <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none d-none">
                        <Link className="nav-item nav-link px-0 me-xl-4" to=" #">
                            <i className="ti ti-menu-2 ti-sm"></i>
                        </Link>
                    </div>
                    <div className="navbar-brand app-brand demo d-xl-flex py-0 me-4 d-none d-lg-flex">
                        <Link className="d-flex" href="/">
                            <img
                                alt="Aargauertaxi"
                                src="../assets/img/template/logo.svg"
                                style={{ height: "24px", width: "210px",marginLeft:'-30px' }}
                            />
                        </Link>

                        <Link to="#" className="layout-menu-toggle menu-link text-large ms-auto d-xl-none">
                            <i className="ti ti-x ti-md align-middle"></i>
                        </Link>
                    </div>

                    <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
                        <Link className="nav-item nav-link px-0 me-xl-4" to="#">
                            <i className="ti ti-menu-2 ti-md"></i>
                        </Link>
                    </div>

                    <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                        <ul className="navbar-nav flex-row align-items-center ms-auto">
                            <li className="nav-item dropdown-language  navbar-dropdown dropdown me-3 me-xl-2 ">
                                <Language />
                            </li>
                            <li className="nav-item dropdown-notifications navbar-dropdown dropdown me-3 me-xl-1">
                                <Link className="nav-link dropdown-toggle hide-arrow" href="#;" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                                    <i className="ti ti-bell ti-md"></i>
                                    <span className="badge bg-danger rounded-pill badge-notifications">5</span>
                                </Link>
                                <ul className="dropdown-menu dropdown-menu-end py-0">
                                    <li className="dropdown-menu-header border-bottom">
                                        <div className="dropdown-header d-flex align-items-center py-3">
                                            <h5 className="text-body mb-0 me-auto">Notification</h5>
                                            <Link href="#" className="dropdown-notifications-all text-body" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Mark all as read" data-bs-original-title="Mark all as read"><i className="ti ti-mail-opened fs-4"></i></Link>
                                        </div>
                                    </li>
                                    <li className="dropdown-notifications-list scrollable-container ps">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item list-group-item-action dropdown-notifications-item">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar">
                                                            <img src="../../assets/img/avatars/1.png" alt="" className="h-auto rounded-circle" />
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-1">Congratulation Lettie 🎉</h6>
                                                        <p className="mb-0">Won the monthly best seller gold badge</p>
                                                        <small className="text-muted">1h ago</small>
                                                    </div>
                                                    <div className="flex-shrink-0 dropdown-notifications-actions">
                                                        <Link to="#" className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                        <Link to="#" className="dropdown-notifications-archive"><span className="ti ti-x"></span></Link>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item list-group-item-action dropdown-notifications-item">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar">
                                                            <span className="avatar-initial rounded-circle bg-label-danger">CF</span>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-1">Charles Franklin</h6>
                                                        <p className="mb-0">Accepted your connection</p>
                                                        <small className="text-muted">12hr ago</small>
                                                    </div>
                                                    <div className="flex-shrink-0 dropdown-notifications-actions">
                                                        <Link to="#" className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                        <Link to="#" className="dropdown-notifications-archive"><span className="ti ti-x"></span></Link>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar">
                                                            <img src="../../assets/img/avatars/2.png" alt="" className="h-auto rounded-circle" />
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-1">New Message ✉️</h6>
                                                        <p className="mb-0">You have new message from Natalie</p>
                                                        <small className="text-muted">1h ago</small>
                                                    </div>
                                                    <div className="flex-shrink-0 dropdown-notifications-actions">
                                                        <Link to="#" className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                        <Link to="#" className="dropdown-notifications-archive"><span className="ti ti-x"></span></Link>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item list-group-item-action dropdown-notifications-item">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar">
                                                            <span className="avatar-initial rounded-circle bg-label-success"><i className="ti ti-shopping-cart"></i></span>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-1">Whoo! You have new order 🛒</h6>
                                                        <p className="mb-0">ACME Inc. made new order $1,154</p>
                                                        <small className="text-muted">1 day ago</small>
                                                    </div>
                                                    <div className="flex-shrink-0 dropdown-notifications-actions">
                                                        <Link to="#" className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                        <Link to="#" className="dropdown-notifications-archive"><span className="ti ti-x"></span></Link>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar">
                                                            <img src="../../assets/img/avatars/9.png" alt="" className="h-auto rounded-circle" />
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-1">Application has been approved 🚀</h6>
                                                        <p className="mb-0">Your ABC project application has been approved.</p>
                                                        <small className="text-muted">2 days ago</small>
                                                    </div>
                                                    <div className="flex-shrink-0 dropdown-notifications-actions">
                                                        <Link to="#" className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                        <Link to="#" className="dropdown-notifications-archive"><span className="ti ti-x"></span></Link>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar">
                                                            <span className="avatar-initial rounded-circle bg-label-success"><i className="ti ti-chart-pie"></i></span>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-1">Monthly report is generated</h6>
                                                        <p className="mb-0">July monthly financial report is generated</p>
                                                        <small className="text-muted">3 days ago</small>
                                                    </div>
                                                    <div className="flex-shrink-0 dropdown-notifications-actions">
                                                        <Link to="#" className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                        <Link to="#" className="dropdown-notifications-archive"><span className="ti ti-x"></span></Link>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar">
                                                            <img src="../../assets/img/avatars/5.png" alt="" className="h-auto rounded-circle" />
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-1">Send connection request</h6>
                                                        <p className="mb-0">Peter sent you connection request</p>
                                                        <small className="text-muted">4 days ago</small>
                                                    </div>
                                                    <div className="flex-shrink-0 dropdown-notifications-actions">
                                                        <Link to="#" className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                        <Link to="#" className="dropdown-notifications-archive"><span className="ti ti-x"></span></Link>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item list-group-item-action dropdown-notifications-item">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar">
                                                            <img src="../../assets/img/avatars/6.png" alt="" className="h-auto rounded-circle" />
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-1">New message from Jane</h6>
                                                        <p className="mb-0">Your have new message from Jane</p>
                                                        <small className="text-muted">5 days ago</small>
                                                    </div>
                                                    <div className="flex-shrink-0 dropdown-notifications-actions">
                                                        <Link to="#" className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                        <Link to="#" className="dropdown-notifications-archive"><span className="ti ti-x"></span></Link>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar">
                                                            <span className="avatar-initial rounded-circle bg-label-warning"><i className="ti ti-alert-triangle"></i></span>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-1">CPU is running high</h6>
                                                        <p className="mb-0">CPU Utilization Percent is currently at 88.63%,</p>
                                                        <small className="text-muted">5 days ago</small>
                                                    </div>
                                                    <div className="flex-shrink-0 dropdown-notifications-actions">
                                                        <Link to="#" className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                        <Link to="#" className="dropdown-notifications-archive"><span className="ti ti-x"></span></Link>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="ps__rail-x" style={{ left: '0px', bottom: '0px' }}><div className="ps__thumb-x" tabIndex="0" style={{ left: '0px', width: '0px' }}></div></div><div className="ps__rail-y" style={{ top: '0px', right: '0px' }}><div className="ps__thumb-y" tabIndex="0" style={{ top: '0px', height: '0px' }}></div></div></li>
                                    <li className="dropdown-menu-footer border-top">
                                        <Link to="#;" className="dropdown-item d-flex justify-content-center text-primary p-2 h-px-40 mb-1 align-items-center">
                                            View all notifications
                                        </Link>
                                    </li>
                                </ul>
                            </li>

                            <li className="nav-item navbar-dropdown dropdown-user dropdown mx-2">
                                <Link
                                    className="nav-link dropdown-toggle hide-arrow p-0"
                                    to="#"
                                    data-bs-toggle="dropdown">
                                    <div className="avatar avatar-online">
                                        <img
                                            src={`${BASE_URL}uploads/${userData.image}`}
                                            alt="avatar"
                                            className="rounded-circle "
                                        />
                                    </div>
                                </Link>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    <li>
                                        <Link className="dropdown-item mt-0" to="#">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 me-2">
                                                    <div className="avatar avatar-online">
                                                        <img
                                                            src={`${BASE_URL}uploads/${userData.image}`}
                                                            alt="avatar"
                                                            className="rounded-circle"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h6 className="mb-0">{userData.first_name || "User"}</h6>
                                                    <small className="text-muted">{userData.role || "Role"}</small>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <div className="dropdown-divider my-1 mx-n2"></div>
                                    </li>
                                    <li>
                                        <Link className="dropdown-item" to="../userProfile">
                                            <i className="ti ti-user me-3 ti-md"></i><span className="align-middle">My Profile</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="dropdown-item" to="pages-account-settings-account.html">
                                            <i className="ti ti-settings me-3 ti-md"></i><span className="align-middle">Settings</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="dropdown-item" to="pages-account-settings-billing.html">
                                            <span className="d-flex align-items-center align-middle">
                                                <i className="flex-shrink-0 ti ti-file-dollar me-3 ti-md"></i
                                                ><span className="flex-grow-1 align-middle">Billing</span>
                                                <span className="flex-shrink-0 badge bg-danger d-flex align-items-center justify-content-center"
                                                >4</span
                                                >
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <div className="dropdown-divider my-1 mx-n2"></div>
                                    </li>
                                    <li>
                                        <Link className="dropdown-item" to="pages-pricing.html">
                                            <i className="ti ti-currency-dollar me-3 ti-md"></i><span className="align-middle">Pricing</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="dropdown-item" to="pages-faq.html">
                                            <i className="ti ti-question-mark me-3 ti-md"></i><span className="align-middle">FAQ</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <div className="d-grid px-2 pt-2 pb-1">
                                            <Link className="btn btn-sm btn-danger d-flex" to="../logout" >
                                                <small className="align-middle">Logout</small>
                                                <i className="ti ti-logout ms-2 ti-14px"></i>
                                            </Link>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <div className="navbar-search-wrapper search-input-wrapper container-xxl d-none">
                        <input
                            type="text"
                            className="form-control search-input border-0"
                            placeholder="Search..."
                            aria-label="Search..." />
                        <i className="ti ti-x search-toggler cursor-pointer"></i>
                    </div>
                </div>
            </nav>

        </>

    )
}

export default Header
