import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { BASE_URL } from '../Constant/Constant'

const ResetPassword = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    const validationSchema = Yup.object({
        newPassword: Yup.string()
            .required('New password is required')
            .min(8, 'Password must be at least 8 characters long')
            .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
            .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
            .matches(/[0-9]/, 'Password must contain at least one number')
            .matches(/[\W_]/, 'Password must contain at least one special character'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
            .required('Confirm password is required'),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const response = await fetch(`${BASE_URL}user/reset-password/${token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ newPassword: values.newPassword }),
            });

            if (response.ok) {
                toast.success('Password has been reset successfully.');
                navigate('/login');
            } else {
                const data = await response.json();
                toast.error(data.message || 'Error resetting password.');
            }
        } catch (error) {
            toast.error('Network error. Please try again.');
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <div className="authentication-wrapper authentication-cover authentication-bg" style={{ backgroundColor: 'white' }}>
                <div className="authentication-inner row">
                    <div className="d-none d-lg-flex col-lg-7 p-0">
                        <div className="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
                            <img
                                src="/assets/img/illustrations/auth-reset-password-illustration-light.png"
                                alt="auth-reset-password-cover"
                                className="img-fluid my-5 auth-illustration"
                                data-app-light-img="illustrations/auth-reset-password-illustration-light.png"
                                data-app-dark-img="illustrations/auth-reset-password-illustration-dark.png"
                            />
                            <img
                                src="/assets/img/illustrations/bg-shape-image-light.png"
                                alt="auth-reset-password-cover"
                                className="platform-bg"
                                data-app-light-img="illustrations/bg-shape-image-light.png"
                                data-app-dark-img="illustrations/bg-shape-image-dark.png"
                            />
                        </div>
                    </div>
                    <div className="d-flex col-12 col-lg-5 align-items-center p-4 p-sm-5">
                        <div className="w-px-400 mx-auto">
                            <div className="app-brand mb-4">
                                <a href="#" className="app-brand-link gap-2">
                                    <span className="app-brand-logo demo">
                                        <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M0.00172773 0V6.85398C0.00172773 6.85398 -0.133178 9.01207 1.98092 10.8388L13.6912 21.9964L19.7809 21.9181L18.8042 9.88248L16.4951 7.17289L9.23799 0H0.00172773Z"
                                                fill="#7367F0"
                                            />
                                            <path
                                                opacity="0.06"
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M7.69824 16.4364L12.5199 3.23696L16.5541 7.25596L7.69824 16.4364Z"
                                                fill="#161616"
                                            />
                                            <path
                                                opacity="0.06"
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M8.07751 15.9175L13.9419 4.63989L16.5849 7.28475L8.07751 15.9175Z"
                                                fill="#161616"
                                            />
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M7.77295 16.3566L23.6563 0H32V6.88383C32 6.88383 31.8262 9.17836 30.6591 10.4057L19.7824 22H13.6938L7.77295 16.3566Z"
                                                fill="#7367F0"
                                            />
                                        </svg>
                                    </span>
                                </a>
                            </div>
                            <h4 className="mb-4">Reset Password 🔒</h4>
                            {/* <p className="mb-4">for <span className="fw-medium">yourmailname@email.com</span></p> */}
                            <Formik
                                initialValues={{ newPassword: '', confirmPassword: '' }}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ isSubmitting }) => (
                                    <Form id="formAuthentication" className="mb-3 needs-validation" noValidate>
                                        <div className="mb-3 form-password-toggle">
                                            <label className="form-label" htmlFor="newPassword">New Password</label>
                                            <div className="input-group ">
                                                <Field
                                                    id="newPassword"
                                                    name="newPassword"
                                                    type={isPasswordVisible ? 'text' : 'password'}
                                                    className="form-control"
                                                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                                />
                                                <span className="input-group-text cursor-pointer" onClick={togglePasswordVisibility}>
                                                    <i className={isPasswordVisible ? "ti ti-eye" : "ti ti-eye-off"}></i>
                                                </span>
                                                
                                            </div>
                                            <ErrorMessage name="newPassword" component="div" className="invalid-feedback" />
                                        </div>
                                        <div className="mb-3 form-password-toggle">
                                            <label className="form-label" htmlFor="confirmPassword">Confirm Password</label>
                                            <div className="input-group input-group-merge">
                                                <Field
                                                    id="confirmPassword"
                                                    name="confirmPassword"
                                                    type={isPasswordVisible ? 'text' : 'password'}
                                                    className="form-control"
                                                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                                />
                                                <span className="input-group-text cursor-pointer" onClick={togglePasswordVisibility}>
                                                    <i className={isPasswordVisible ? "ti ti-eye" : "ti ti-eye-off"}></i>
                                                </span>
                                                
                                            </div>
                                            <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                                        </div>
                                        <button className="btn btn-primary d-grid w-100 mb-3" type="submit" disabled={isSubmitting}>
                                            {isSubmitting ? 'Submitting...' : 'Set New Password'}
                                        </button>
                                        <div className="text-center">
                                            <a href="/login">
                                                <i className="ti ti-chevron-left scaleX-n1-rtl"></i>
                                                Back to Login
                                            </a>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResetPassword;
