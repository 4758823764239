
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {BASE_URL,BACKENDTOKEN} from '../../../../Constant/Constant';
import { toast } from 'react-toastify';


const FooterContent = () => {
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        MyaccountText: '',
        SignupText: '',
        LoginText: '',
        PrivacyPolicyText: '',
        RefundText: '',
        LicensesText: '',
        TermsConditionsText: '',
        FaqText: '',
        BusinessText: '',
        ContactusText: '',
        AboutusText: '',
        AccountText: '',
        LegalText: '',
        CompanyText: '',
    });
const [isEditMode, setIsEditMode] = useState(false);
const [isDataExists, setIsDataExists] = useState(false);
const [languageId, setLanguageId] = useState(localStorage.getItem('selectedLanguageId'));
const [id, setId] = useState(null); // Initialize with null

useEffect(() => {
    const fetchData = async () => {
        try {
            const url = `${BASE_URL}staticcontent/get?language_id=${languageId}`;
            const response = await axios.get(url,{
                headers:{
                    'Authorization': `Bearer ${BACKENDTOKEN}`
                }
            });
            setId(response.data.id);
            if (response.data && response.data.footercontent) {
                const parsedData = JSON.parse(response.data.footercontent);
                setFormValues(parsedData);
                setIsDataExists(true);
                setIsEditMode(false); // Data exists, so not in edit mode


            } else {

                setFormValues({
                    MyaccountText: '',
                    SignupText: '',
                    LoginText: '',
                    PrivacyPolicyText: '',
                    RefundText: '',
                    LicensesText: '',
                    TermsConditionsText: '',
                    FaqText: '',
                    BusinessText: '',
                    ContactusText: '',
                    AboutusText: '',
                    AccountText: '',
                    LegalText: '',
                    CompanyText: '',
                });
                setIsDataExists(false);
                setIsEditMode(true); // No data, so allow adding
            }
        } catch (error) {
            setIsEditMode(true); // Error fetching data, possibly allow editing
        }
    };

    fetchData();
}, [languageId, id]);

const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues(prevValues => ({
        ...prevValues,
        [name]: value
    }));
};


const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
        const payload = {
            footercontent: formValues,
            language_id: languageId
        };

        const url = `${BASE_URL}staticcontent/footercontent`;

        const response = await axios.post(url, payload,{
            headers:{
                'Authorization': `Bearer ${BACKENDTOKEN}`
            }
        });

        // Check if response data is in the expected format
        if (response.data && response.data.message === 'footercontent Process successfully!') {
            toast.dismiss()
            toast.success('Data processed successfully!', {
                autoClose: 1500,
            });
            setIsDataExists(true);

        } else {
            toast.dismiss()
            toast.warn('Unexpected response from the server.', {
                autoClose: 1500,
            });
        }

        setIsEditMode(false);
    } catch (error) {
       toast.dismiss()
        toast.error('Error saving data.', {
            autoClose: 1500,
        });
    } finally {
        setLoading(false);
    }
};


return (
    <>
        <form className="row g-3" onSubmit={handleSubmit}>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Company Text</label>
                    <input
                        name="CompanyText"
                        type="text"
                        className="form-control"
                        value={formValues.CompanyText}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Legal Text</label>
                    <input
                        name="LegalText"
                        type="text"
                        className="form-control"
                        value={formValues.LegalText}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Account Text</label>
                    <input
                        name="AccountText"
                        type="text"
                        className="form-control"
                        value={formValues.AccountText}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
            </div>


            {/* Second Row */}
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Aboutus Text</label>
                    <input
                        name="AboutusText"
                        type="text"
                        className="form-control"
                        value={formValues.AboutusText}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Contactus Text</label>
                    <input
                        name="ContactusText"
                        type="text"
                        className="form-control"
                        value={formValues.ContactusText}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Business Text</label>
                    <input
                        name="BusinessText"
                        type="text"
                        className="form-control"
                        value={formValues.BusinessText}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
            </div>

            {/* three Row */}
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Faq Text</label>
                    <input
                        name="FaqText"
                        type="text"
                        className="form-control"
                        value={formValues.FaqText}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Terms&Conditions Text</label>
                    <input
                        name="TermsConditionsText"
                        type="text"
                        className="form-control"
                        value={formValues.TermsConditionsText}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">LicensesText</label>
                    <input
                        name="LicensesText"
                        type="text"
                        className="form-control"
                        value={formValues.LicensesText}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
            </div>

            {/* fouth Row */}
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Refund Text</label>
                    <input
                        name="RefundText"
                        type="text"
                        className="form-control"
                        value={formValues.RefundText}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">PrivacyPolicy Text</label>
                    <input
                        name="PrivacyPolicyText"
                        type="text"
                        className="form-control"
                        value={formValues.PrivacyPolicyText}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">LoginText</label>
                    <input
                        name="LoginText"
                        type="text"
                        className="form-control"
                        value={formValues.LoginText}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
            </div>

            {/* five Row */}
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">SignupText</label>
                    <input
                        name="SignupText"
                        type="text"
                        className="form-control"
                        value={formValues.SignupText}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">MyaccountText</label>
                    <input
                        name="MyaccountText"
                        type="text"
                        className="form-control"
                        value={formValues.MyaccountText}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
            </div>

            <div className="card-footer text-end">
                <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loading}
                >
                    {loading ? 'Saving...' : (isDataExists ? 'Update' : 'Add')}
                </button>
            </div>

        </form>
    </>
);
};

export default FooterContent;
