
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL, BACKENDTOKEN } from '../../../../Constant/Constant';
import { toast } from 'react-toastify';

const MyAccountMenu = () => {
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({

        ActionTitle:'',
        StatusTitle:'',
        PaymentStatusTitle:'',
        TotalPriceTitle:'',
        FromAndToTitle:'',
        DataAndTimeTitle:'',
        OrderIdTitle:'',
        BookingDetailTitle:'',
        CurrentBookingTitle:'',
    });
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDataExists, setIsDataExists] = useState(false);
    const [languageId, setLanguageId] = useState(localStorage.getItem('selectedLanguageId'));
    const [id, setId] = useState(null); // Initialize with null

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${BASE_URL}staticcontent/get?language_id=${languageId}`;
                const response = await axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${BACKENDTOKEN}`
                    }
                });

                setId(response.data.id);
                if (response.data && response.data.myaccountmenu) {
                    const parsedData = JSON.parse(response.data.myaccountmenu);
                    setFormValues(parsedData);
                    setIsDataExists(true);
                    setIsEditMode(false); // Data exists, so not in edit mode


                } else {

                    setFormValues({
                        ActionTitle:'',
                        StatusTitle:'',
                        PaymentStatusTitle:'',
                        TotalPriceTitle:'',
                        FromAndToTitle:'',
                        DataAndTimeTitle:'',
                        OrderIdTitle:'',
                        BookingDetailTitle:'',
                        CurrentBookingTitle:'',
                    });
                    setIsDataExists(false);
                    setIsEditMode(true); // No data, so allow adding
                }
            } catch (error) {
                setIsEditMode(true);
            }
        };

        fetchData();
    }, [languageId, id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const payload = {
                myaccountmenu: JSON.stringify(formValues),
                language_id: languageId
            };

            const url = `${BASE_URL}staticcontent/myaccountmenu`;

            const response = await axios.post(url, payload, {
                headers: {
                    'Authorization': `Bearer ${BACKENDTOKEN}`
                }
            });

            // Check if response data is in the expected format
            if (response.data && response.data.message === 'myaccountmenu Process successfully!') {
                toast.dismiss()
                toast.success('Data processed successfully!', {
                    autoClose: 1500,
                });
                setIsDataExists(true);

            } else {
                toast.dismiss()
                toast.warn('Unexpected response from the server.', {
                    autoClose: 1500,
                });
            }

            setIsEditMode(false);
        } catch (error) {
            toast.dismiss()
            toast.error('Error saving data.', {
                autoClose: 1500,
            });
        } finally {
            setLoading(false);
        }
    };


    return (
        <>
            <form className="row g-3" onSubmit={handleSubmit}>
                {/* First Row */}
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">CurrentBooking Title</label>
                        <input
                            name="CurrentBookingTitle"
                            type="text"
                            className="form-control"
                            value={formValues.CurrentBookingTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">BookingDetail Title</label>
                        <input
                            name="BookingDetailTitle"
                            type="text"
                            className="form-control"
                            value={formValues.BookingDetailTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">OrderId Title</label>
                        <input
                            name="OrderIdTitle"
                            type="text"
                            className="form-control"
                            value={formValues.OrderIdTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">DataAndTime Title</label>
                        <input
                            name="DataAndTimeTitle"
                            type="text"
                            className="form-control"
                            value={formValues.DataAndTimeTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>

                {/* Second Row */}
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">FromAndTo Title</label>
                        <input
                            name="FromAndToTitle"
                            type="text"
                            className="form-control"
                            value={formValues.FromAndToTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">TotalPrice Title</label>
                        <input
                            name="TotalPriceTitle"
                            type="text"
                            className="form-control"
                            value={formValues.TotalPriceTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">PaymentStatusTitle</label>
                        <input
                            name="PaymentStatusTitle"
                            type="text"
                            className="form-control"
                            value={formValues.PaymentStatusTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">StatusTitle</label>
                        <input
                            name="StatusTitle"
                            type="text"
                            className="form-control"
                            value={formValues.StatusTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">Action Title</label>
                        <input
                            name="ActionTitle"
                            type="text"
                            className="form-control"
                            value={formValues.ActionTitle}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>
                </div>



                <div className="card-footer text-end">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loading}
                    >
                        {loading ? 'Saving...' : (isDataExists ? 'Update' : 'Add')}
                    </button>
                </div>

            </form>
        </>
    );
};

export default MyAccountMenu;

