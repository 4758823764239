import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { MdOutlineCancel } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoAddCircleOutline } from "react-icons/io5";
import {BASE_URL,BACKENDTOKEN} from '../../../Constant/Constant'
import { useSelector } from 'react-redux';

const ActionCarList = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { id } = useParams();
  const action = queryParams.get('action');
  const navigate = useNavigate();

  const [carDetails, setCarDetails] = useState({
    title: '',
    image: '',
    preview_image: '',
    short_description: '',
    Language: '',
    seats: '',
    luggage: '',
    start: '',
    day_price: '',
    night_price: '',
    discount: '',
    description: '',
    carcategory_id: '',
    points: []
  });

  const [isEditable, setIsEditable] = useState(false);
  const [message, setMessage] = useState(null);
  const [carCategories, setCarCategories] = useState([]);
  const [errors, setErrors] = useState({});
   
const permissions = useSelector(state => state.roles.permissions.user_modules);
const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });

useEffect(() => {
    if (permissions) {
        try {
            const modules = JSON.parse(permissions);
            const car = modules.find(module => module.rolename === 'car');
            if (car) {
                setRolePermissions(car.permissions);
            }
        } catch (error) {
            console.error('Failed to parse permissions:', error);
        }
    }
}, [permissions]);


  useEffect(() => {
    if (id) {
      axios.get(`${BASE_URL}car/get/${id}`, {
        headers: {
            'Authorization': `Bearer ${BACKENDTOKEN}`
        }
    })
        .then(response => {
          const data = response.data;
          setCarDetails({
            title: data.title || '',
            image: data.image || '',
            preview_image: data.preview_image || '',
            short_description: data.short_description || '',
            Language: data.language_id || '',
            seats: data.seats || '',
            luggage: data.luggage || '',
            start_price: data.start_price || '',
            day_price: data.day_price || '',
            night_price: data.night_price || '',
            discount: data.discount || '',
            description: data.description || '',
            carcategory_id: data.carcategory_id || '',
            points: Array.isArray(data.points) ? data.points : JSON.parse(data.points || '[]')
          });
        })
        .catch(error => {
          console.error('Error fetching car details:', error);
        });
    }
  }, [id]);

  useEffect(() => {
    setIsEditable(action === 'edit');
  }, [action]);

  useEffect(() => {
    const fetchCarCategories = async () => {
      try {
        const response = await axios.get(`${BASE_URL}carcategory/get`, {
          headers: {
              'Authorization': `Bearer ${BACKENDTOKEN}`
          }
      });
        setCarCategories(response.data);
      } catch (error) {
        console.error('Error fetching car categories:', error);
      }
    };

    fetchCarCategories();
  }, []);

  const handleUpdate = () => {
    if (rolePermissions.write === 'no') {
      toast.dismiss();
      toast.error('You do not have permission to update data.', {
          autoClose: 1500,             
  });
      return;
  }
  
    const formData = new FormData();
    formData.append('title', carDetails.title);
    formData.append('image', carDetails.image);
    formData.append('preview_image', carDetails.preview_image);
    formData.append('short_description', carDetails.short_description);
    formData.append('Language', carDetails.Language);
    formData.append('seats', carDetails.seats);
    formData.append('luggage', carDetails.luggage);
    formData.append('start_price', carDetails.start_price);
    formData.append('day_price', carDetails.day_price);
    formData.append('night_price', carDetails.night_price);
    formData.append('discount', carDetails.discount);
    formData.append('description', carDetails.description);
    formData.append('carcategory_id', carDetails.carcategory_id);
    formData.append('points', JSON.stringify(carDetails.points));

    axios.put(`${BASE_URL}car/update/${id}`, formData, {
      headers: {
        'Authorization': `Bearer ${BACKENDTOKEN}`,
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        toast.dismiss();
        toast.success('Car details updated successfully', {
          autoClose: 1500,             
});
        setTimeout(() => {
          setMessage(null);
          navigate(`/actioncarlist/${id}?action=view`);
        });
      })
      .catch(error => {
        toast.dismiss();
        toast.error('Failed to update car details', {
          autoClose: 1500,             
});
        setTimeout(() => {
          setMessage(null);
        }, 2000);
      });
  };

  const handleEdit = () => {
    navigate(`/actioncarlist/${id}?action=edit`);
  };

  const handleCancel = () => {
    navigate(`/actioncarlist/${id}?action=view`);
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setCarDetails(prevState => ({
      ...prevState,
      [name]: type === 'file' ? files[0] : value
    }));
  };

  const handledescriptionChange = (value) => {
    setCarDetails(prevState => ({
      ...prevState,
      description: value
    }));
  };

  const handlepointsChange = (e, index) => {
    const newpoints = [...carDetails.points];
    newpoints[index] = e.target.value;
    setCarDetails(prevState => ({
      ...prevState,
      points: newpoints
    }));
  };

  const handleRemovePoint = (index) => {
    const newpoints = [...carDetails.points];
    newpoints.splice(index, 1);
    setCarDetails(prevState => ({
      ...prevState,
      points: newpoints
    }));
  };

  const handleAddPoint = () => {
    setCarDetails(prevState => ({
      ...prevState,
      points: [...prevState.points, '']
    }));
  };

  return (
    <div className="container-xl my-4">
      <div className="row">
        <div className="col-md-6">
          <h4>Cars</h4>
        </div>
        <div className="col-md-6">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-end fs-3">
              <li className="breadcrumb-item"><Link to="/carlist" className='fs-6'>Back</Link></li>
              <li className="breadcrumb-item"><Link to="#" className='fs-6'>ActionPage</Link></li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="col-12 mt-3">
        <div className="card mt-3">
          <div className="card-header header-underline pb-1">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="card-name">{action === 'edit' ? 'Edit Details' : 'View Details'}</h5>
            </div>
          </div>
          <div className="card-body mt-3">
            <form className="row g-3">
              <div className="col-md-4">
                <label className="col-sm col-form-label" htmlFor="carcategory_id">Car Category</label>
                <select
                  name="carcategory_id"
                  className={`form-select ${errors.carcategory_id ? 'is-invalid' : ''}`}
                  value={carDetails.carcategory_id}
                  onChange={handleChange}
                  disabled={!isEditable}
                >
                  <option value="">Select Car Category</option>
                  {carCategories.map(category => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
                {errors.carcategory_id && <div className="invalid-feedback">{errors.carcategory_id}</div>}
              </div>
              <div className="col-md-4">
                <label className="col-sm col-form-label" htmlFor="title">Title</label>
                <input
                  type="text"
                  name="title"
                  value={carDetails.title}
                  onChange={handleChange}
                  disabled={!isEditable}
                  className="form-control mb-2"
                />
              </div>
              <div className="col-md-4">
                <label className="col-sm col-form-label" htmlFor="short_description">Short description</label>
                <input
                  type="text"
                  name="short_description"
                  value={carDetails.short_description}
                  onChange={handleChange}
                  disabled={!isEditable}
                  className="form-control mb-2"
                />
              </div>
              <div className="col-md-4">
                <label className="col-sm col-form-label" htmlFor="seats">Seats</label>
                <input
                  type="text"
                  name="seats"
                  value={carDetails.seats}
                  onChange={handleChange}
                  disabled={!isEditable}
                  className="form-control mb-2"
                />
              </div>
              <div className="col-md-4">
                <label className="col-sm col-form-label" htmlFor="luggage">Luggage</label>
                <input
                  type="text"
                  name="luggage"
                  value={carDetails.luggage}
                  onChange={handleChange}
                  disabled={!isEditable}
                  className="form-control mb-2"
                />
              </div>
              <div className="col-md-4">
                <label className="col-sm col-form-label" htmlFor="start_price">Start Price</label>
                <input
                  type="text"
                  name="start_price"
                  value={carDetails.start_price}
                  onChange={handleChange}
                  disabled={!isEditable}
                  className="form-control mb-2"
                />
              </div>
              <div className="col-md-4">
                <label className="col-sm col-form-label" htmlFor="day_price">Day Price</label>
                <input
                  type="text"
                  name="day_price"
                  value={carDetails.day_price}
                  onChange={handleChange}
                  disabled={!isEditable}
                  className="form-control mb-2"
                />
              </div>
              <div className="col-md-4">
                <label className="col-sm col-form-label" htmlFor="night_price">Night Price</label>
                <input
                  type="text"
                  name="night_price"
                  value={carDetails.night_price}
                  onChange={handleChange}
                  disabled={!isEditable}
                  className="form-control mb-2"
                />
              </div>
              <div className="col-md-4">
                <label className="col-sm col-form-label" htmlFor="discount">Discount</label>
                <input
                  type="text"
                  name="discount"
                  value={carDetails.discount}
                  onChange={handleChange}
                  disabled={!isEditable}
                  className="form-control mb-2"
                />
              </div>

              <div className="row g-3">
                <div className="col-md-6">
                  <label className="col-sm col-form-label" >Description</label>
                  {isEditable ? (
                    <ReactQuill
                      theme="snow"
                      value={carDetails.description}
                      onChange={handledescriptionChange}
                      modules={{
                        toolbar: [
                          [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                          [{ 'size': [] }],
                          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                          ['link', 'image'],
                          ['clean']
                        ]
                      }}
                      style={{ height: '130px', borderRadius: '10px' }}
                      className="react-quill-editor mb-3"
                    />
                  ) : (
                    <div
                      className="form-control mb-2"
                      dangerouslySetInnerHTML={{ __html: carDetails.description }}
                      style={{ minHeight: '150px', backgroundColor: '#f4f4f4' }}
                    />
                  )}
                </div>
                <div className="col-md-3">
                  <label className="col-sm col-form-label" htmlFor="image">Image</label>
                  <div className="mb-3">
                    {carDetails.image && (
                      <img
                        src={`${BASE_URL}uploads/${carDetails.image}`}
                        alt="image Preview"
                        className="img-fluid mb-2"
                        style={{ maxHeight: '140px', width: '100%', borderRadius: "5px" }}
                      />
                    )}
                  </div>
                  {isEditable && (
                    <input
                      type="file"
                      name="image"
                      onChange={handleChange}
                      disabled={!isEditable}
                      className="form-control mb-2"
                    />
                  )}
                </div>
                <div className="col-md-3">
                  <label className="col-sm col-form-label" htmlFor="preview_image">Preview image</label>
                  <div className="mb-3">
                    {carDetails.preview_image && (
                      <img
                        src={`${BASE_URL}uploads/${carDetails.preview_image}`}
                        alt="image Preview"
                        className="img-fluid mb-2"
                        style={{ maxHeight: '140px', width: '100%', borderRadius: "5px" }}
                      />
                    )}
                  </div>
                  {isEditable && (
                    <input
                      type="file"
                      name="preview_image"
                      onChange={handleChange}
                      disabled={!isEditable}
                      className="form-control mb-2"
                    />
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <label className="col-sm col-form-label" >Points:</label>
                  <div className=''>
                  {carDetails.points.map((point, index) => (
                    <div key={index} className='input-group mb-3'>
                      <input
                        type="text"
                        value={point}
                        onChange={(e) => handlepointsChange(e, index)}
                        required
                        disabled={!isEditable}
                        className="form-control "
                      />
                      {isEditable && (
                        <button type="button" className='btn btn-label-secondary  waves-effect waves-light' onClick={() => handleRemovePoint(index)}>
                          <MdOutlineCancel />
                        </button>
                      )}
                    </div>
                  ))}
                  {isEditable && (
                    <button type="button" className="btn btn-primary" onClick={handleAddPoint}>
                      <IoAddCircleOutline style={{ fontSize: "16px" }} />
                    </button>
                  )}
                  </div>
                </div>
              </div>
            </form>
            </div>
            <div className='card-footer'>
              <div className="d-flex justify-content-end align-items-center">
                {action === 'view' &&   rolePermissions.write === 'yes' && (
                  <button type="button" onClick={handleEdit} className="btn btn-primary mb-2">Edit</button>
                )}
                {isEditable && (
                  <button type="button" onClick={handleUpdate} className="btn btn-primary mx-3">Update</button>
                )}
                {action === 'edit' && (
                  <button type="button" onClick={handleCancel} className=' btn btn-label-secondary'>Cancel</button>
                )}
              </div>
          
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionCarList;


