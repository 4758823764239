import React from 'react';
import { Link } from 'react-router-dom';

const TableFooter = ({ totalRecords, currentPage, recordsPerPage, onPageChange }) => {
    const totalPages = Math.ceil(totalRecords / recordsPerPage);
    const pageNumbers = [...Array(totalPages).keys()].map(i => i + 1);

    return (
   
        <div className="row mt-3">
            <div className="col-sm-12 col-md-6  justify-content-between">
                <div className="dataTables_info" role="status" aria-live="polite">
                    Records: {Math.min((currentPage - 1) * recordsPerPage + 1, totalRecords)} to {Math.min(currentPage * recordsPerPage, totalRecords)} of {totalRecords}
                </div>
            </div>
            <div className="col-sm-12 col-md-6">
                <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-end">
                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <Link className="page-link waves-effect" to="#" onClick={() => onPageChange(currentPage - 1)}>
                                <i className="ti ti-chevrons-left ti-xs"></i>
                            </Link>
                        </li>
                        {pageNumbers.map(number => (
                            <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                                <Link className="page-link waves-effect" to="#" onClick={() => onPageChange(number)}>
                                    {number}
                                </Link>
                            </li>
                        ))}
                        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                            <Link className="page-link waves-effect" to="#" onClick={() => onPageChange(currentPage + 1)}>
                                <i className="ti ti-chevrons-right ti-xs"></i>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    
    );
};

export default TableFooter;
