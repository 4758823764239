import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import AboutUs from "./Components/PageSetting/AboutUs/AboutUs";
import AddServices from "./Components/Services/AddServices";
import ServicesList from "./Components/Services/ServicesList";
import Dashboard from "./Views/Dashboard";
import NoPage from "./Views/NoPage";
import ExtraOptionsList from "./Components/ExtraOptions/ExtraOptionsList";
import LegalContents from "./Components/PageSetting/LegalContents/LegalContents";
import FAQ from "./Components/PageSetting/FAQ/FAQ";
import ContactUs from "./Components/PageSetting/ContactUs/ContactUs";
import CarList from "./Components/Car/CarList/CarList";
import AddCar from "./Components/Car/CarList/AddCar";
import WelcomeMessage from "./Components/Taxi/Welcome/WelcomeMessage";
import OfferInfo from "./Components/Taxi/OfferInfo/OfferInfo";
import CarCategory from "./Components/Car/CarCategory/CarCategory";
import AddLegalContents from "./Components/PageSetting/LegalContents/AddLegalContents";
import Logout from "./Views/Logout";
import AddContactUs from "./Components/PageSetting/ContactUs/AddContactUs";
import AddAbout from './Components/PageSetting/AboutUs/AddAbout'
import StaticContent from "./Components/PageSetting/StaticContent/StaticContent";
import ActionAbout from "./Components/PageSetting/AboutUs/ActionAbout";
import ActionFAQ from "./Components/PageSetting/FAQ/ActionFAQ";
import Actionwelcome from "./Components/Taxi/Welcome/ActionWelcome";
import ActionOfferInfo from "./Components/Taxi/OfferInfo/ActionOfferInfo";
import ActionServices from "./Components/Services/ActionServices";
import ActionExtra from "./Components/ExtraOptions/ActionExtra";
import ActionContact from "./Components/PageSetting/ContactUs/ActionContact";
import ActionCarList from "./Components/Car/CarList/ActionCarList";
import ActionCarCategory from "./Components/Car/CarCategory/ActionCarCategory";
import ActionLegal from "./Components/PageSetting/LegalContents/ActionLegal";
import TimeSetting from "./Components/CentralSetting/TimeSetting/TimeSetting";
import Currency from "./Components/CentralSetting/Currency/Currency";
import BookingList from "./Components/Booking/BookingList";
import ActionBooking from "./Components/Booking/ActionBooking";
import UserProfile from "./Components/Profile/UserProfile";
import ChangePassword from "./Components/Profile/ChangePassword";
import AddUser from './Components/Users/AddUser/AddUser'
import Users from './Components/Users/Users'
import EditUser from './Components/Users/EditUser'
import MetaTags from './Components/MetaTags/MetaTags'
import ActionMetaTags from "./Components/MetaTags/ActionMetaTags";

export const RoutesAll = () => {
    const permissions = useSelector(state => state.roles.permissions.user_modules);
    const [rolePermissions, setRolePermissions] = useState({});

    useEffect(() => {
        if (permissions) {
            try {
                const modules = JSON.parse(permissions);
                const permissionsMap = {};
                modules.forEach(module => {
                    permissionsMap[module.rolename] = module.permissions;
                });
                setRolePermissions(permissionsMap);
                console.log("rolepermissions", permissionsMap);
            } catch (error) {
                console.error('Failed to parse permissions:', error);
            }
        }
    }, [permissions]);

    console.log("rolePermissions_routes",rolePermissions)

    return [
    {
        path: "/dashboard",
        component: Dashboard,
        exact: true,

    },
    {
        path: "*",
        component: NoPage,
        exact: true,
        

    },
    {
        path: "/aboutus",
        component: AboutUs,
        exact: true,
        condition:  rolePermissions['aboutus'] && (rolePermissions['aboutus'].read === 'yes' || rolePermissions['aboutus'].write === 'yes')

    },
    
    {
        path: "/addservices",
        component: AddServices,
        exact: true,
        condition: rolePermissions['services'] && ( rolePermissions['services'].write === 'yes')

    },
    
    {
        path: "/serviceslist",
        component: ServicesList,
        exact: true,
        condition: rolePermissions['services'] && (rolePermissions['services'].read === 'yes' || rolePermissions['services'].write === 'yes')

    },
    {
        path: "/extraoptionslist",
        component: ExtraOptionsList,
        exact: true,
        condition: rolePermissions['extraoption'] && (rolePermissions['extraoption'].read === 'yes' || rolePermissions['extraoption'].write === 'yes')

    },
    {
        path: "/legalcontents",
        component: LegalContents,
        exact: true,
        condition: rolePermissions['legalcontent'] && (rolePermissions['legalcontent'].read === 'yes' || rolePermissions['legalcontent'].write === 'yes')

    },
    {
        path: "/faq",
        component: FAQ,
        exact: true,
        condition: rolePermissions['faq'] && (rolePermissions['faq'].read === 'yes' || rolePermissions['faq'].write === 'yes')

    },
    {
        path: "/contactus",
        component: ContactUs,
        exact: true,
        condition: rolePermissions['contactus'] && (rolePermissions['contactus'].read === 'yes' || rolePermissions['contactus'].write === 'yes')

    },
    {
        path: "/carlist",
        component: CarList,
        exact: true,
        condition: rolePermissions['car'] && (rolePermissions['car'].read === 'yes' || rolePermissions['car'].write === 'yes')

    },
    {
        path: "/addcar",
        component: AddCar,
        exact: true,
        condition: rolePermissions['car'] && ( rolePermissions['car'].write === 'yes')

    },
    {
        path: "/welcomemessage",
        component: WelcomeMessage,
        exact: true,
        condition: rolePermissions['welcome'] && (rolePermissions['welcome'].read === 'yes' || rolePermissions['welcome'].write === 'yes')


    },
    {
        path: "/offerinfo",
        component: OfferInfo,
        exact: true,
        condition: rolePermissions['offerinfo'] && (rolePermissions['offerinfo'].read === 'yes' || rolePermissions['offerinfo'].write === 'yes')


    },
    {
        path: "/carcategory",
        component: CarCategory,
        exact: true,
        condition: rolePermissions['carcategory'] && (rolePermissions['carcategory'].read === 'yes' || rolePermissions['carcategory'].write === 'yes')


    },
    {
        path: "/addlegalcontents",
        component: AddLegalContents,
        exact: true,
        condition: rolePermissions['legalcontent'] && ( rolePermissions['legalcontent'].write === 'yes')


    },
    {
        path: "/logout",
        component: Logout,
        exact: true

    },
    {
        path: "/addcontactus",
        component: AddContactUs,
        exact: true,
        condition: rolePermissions['contactus'] && ( rolePermissions['contactus'].write === 'yes')

    },
    {
        path: "/addabout",
        component: AddAbout,
        exact: true,
        condition: rolePermissions['aboutus'] && ( rolePermissions['aboutus'].write === 'yes')

    },
    {
        path: "/staticcontent",
        component: StaticContent,
        exact: true,
        condition: rolePermissions['staticcontent'] && (rolePermissions['staticcontent'].read === 'yes' || rolePermissions['staticcontent'].write === 'yes')


    },
    {
        path: "/actionabout/:id",
        component: ActionAbout,
        exact: true,
        condition: rolePermissions['aboutus'] && (rolePermissions['aboutus'].read === 'yes' || rolePermissions['aboutus'].write === 'yes')



    },
    {
        path: "/faqaction/:id",
        component: ActionFAQ,
        exact: true,
        condition: rolePermissions['faq'] && (rolePermissions['faq'].read === 'yes' || rolePermissions['faq'].write === 'yes')


    },
    {
        path: "/actionofferinfo/:id",
        component: ActionOfferInfo,
        exact: true,
        condition: rolePermissions['offerinfo'] && (rolePermissions['offerinfo'].read === 'yes' || rolePermissions['offerinfo'].write === 'yes')


    },
    {
        path: "/actionwelcome/:id",
        component: Actionwelcome,
        exact: true,
        condition: rolePermissions['welcome'] && (rolePermissions['welcome'].read === 'yes' || rolePermissions['welcome'].write === 'yes')


    },
    {
        path: "/actionextra/:id",
        component: ActionExtra,
        exact: true,
        condition: rolePermissions['extraoption'] && (rolePermissions['extraoption'].read === 'yes' || rolePermissions['extraoption'].write === 'yes')



    },
    {
        path: "/actionservice/:id",
        component: ActionServices,
        exact: true,
        condition: rolePermissions['services'] && (rolePermissions['services'].read === 'yes' || rolePermissions['services'].write === 'yes')



    },
    {
        path: "/actioncarlist/:id",
        component: ActionCarList,
        exact: true,
        condition: rolePermissions['car'] && (rolePermissions['car'].read === 'yes' || rolePermissions['car'].write === 'yes')



    },
    {
        path: "/actioncontact/:id",
        component: ActionContact,
        exact: true,
        condition: rolePermissions['contactus'] && (rolePermissions['contactus'].read === 'yes' || rolePermissions['contactus'].write === 'yes')


    },
    {
        path: "/actioncarcategory/:id",
        component: ActionCarCategory,
        exact: true,
        condition: rolePermissions['carcategory'] && (rolePermissions['carcategory'].read === 'yes' || rolePermissions['carcategory'].write === 'yes')


    },
    {
        path: "/actionlegal/:id",
        component: ActionLegal,
        exact: true,
        condition: rolePermissions['legalcontent'] && (rolePermissions['legalcontent'].read === 'yes' || rolePermissions['legalcontent'].write === 'yes')


    },
    {
        path: "/timeSetting",
        component: TimeSetting,
        exact: true,
        condition: rolePermissions['centralSetting'] && (rolePermissions['centralSetting'].read === 'yes' || rolePermissions['centralSetting'].write === 'yes')

    },
    
    {
        path: "/currency",
        component: Currency,
        exact: true,
        condition: rolePermissions['centralSetting'] && (rolePermissions['centralSetting'].read === 'yes' || rolePermissions['centralSetting'].write === 'yes')

    },
    {
        path: "/bookinglist",
        component: BookingList,
        exact: true,
        condition: rolePermissions['Booking'] && (rolePermissions['Booking'].read === 'yes' || rolePermissions['Booking'].write === 'yes')

    },
    {
        path: "/ActionBooking/:id",
        component: ActionBooking,
        exact: true,
        condition: rolePermissions['Booking'] && (rolePermissions['Booking'].read === 'yes' || rolePermissions['Booking'].write === 'yes')

    },
    {
        path: "/userProfile",
        component:UserProfile,
        exact: true
    },
    {
        path: "/changePassword",
        component:ChangePassword,
        exact: true
    },
    {
        path: "/AddUser",
        component:AddUser,
        exact: true,
        condition: rolePermissions['Users'] && ( rolePermissions['Users'].write === 'yes')

    },
    
    {
        path: "/userlist",
        component:Users,
        exact: true,
        condition: rolePermissions['Users'] && (rolePermissions['Users'].read === 'yes' || rolePermissions['Users'].write === 'yes')

    },
    {
        path: "/edituser/:id",
        component:EditUser,
        exact: true,
        condition: rolePermissions['Users'] && (rolePermissions['Users'].read === 'yes' || rolePermissions['Users'].write === 'yes')

    },
    {
        path: "/metatags",
        component:MetaTags,
        exact: true,
        condition: rolePermissions['MetaTags'] && (rolePermissions['MetaTags'].read === 'yes' || rolePermissions['MetaTags'].write === 'yes')

    },
    {
        path: "/action_metatags/:id",
        component:ActionMetaTags,
        exact: true,
        condition: rolePermissions['MetaTags'] && (rolePermissions['MetaTags'].read === 'yes' || rolePermissions['MetaTags'].write === 'yes')

    },
].filter(route => route.condition === undefined || route.condition); // Only return routes where condition is met
};