import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL, BACKENDTOKEN } from '../../../../Constant/Constant';
import { toast } from 'react-toastify';

const HeaderMenu = () => {
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        HomeTitle: '',
        AboutTitle: '',
        OurFleetTitle: '',
        ServicesTitle: '',
        ContactTitle: '',
        PhoneNumber: '',
        ProfileTitle: '',
        ChangePasswordTitle: '',
        BookingListTitle: '',
        Logout: '',
        RegisterTitle: '',
        LoginTitle: ''
    });
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDataExists, setIsDataExists] = useState(false);
    const [languageId, setLanguageId] = useState(localStorage.getItem('selectedLanguageId') || '1'); // Fallback to '1'
    const [id, setId] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Show loading indicator while fetching data
            try {
                const url = `${BASE_URL}staticcontent/get?language_id=${languageId}`;
                const response = await axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${BACKENDTOKEN}`
                    }
                });

                if (response.data && response.data.headermenu) {
                    const parsedData = JSON.parse(response.data.headermenu);
                    setFormValues(parsedData);
                    setId(response.data.id);
                    setIsDataExists(true);
                    setIsEditMode(false);
                } else {
                    // No data exists
                    setFormValues({
                        HomeTitle: '',
                        AboutTitle: '',
                        OurFleetTitle: '',
                        ServicesTitle: '',
                        ContactTitle: '',
                        PhoneNumber: '',
                        RegisterTitle: '',
                        LoginTitle: ''

                    });
                    setIsDataExists(false);
                    setIsEditMode(true); // Allow user to add new data
                }
            } catch (error) {
                setIsEditMode(true); // Allow editing in case of failure
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [languageId]); // Removed `id` from the dependency array

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const payload = {
                headermenu: JSON.stringify(formValues), // Stringify form data for sending
                language_id: languageId
            };

            const url = `${BASE_URL}staticcontent/headermenu`;
            const response = await axios.post(url, payload, {
                headers: {
                    'Authorization': `Bearer ${BACKENDTOKEN}`
                }
            });

            if (response.data && response.data.message === 'headermenu Process successfully!') {
                toast.dismiss()
                toast.success('Data processed successfully!', {
                    autoClose: 1500,
                });
                setIsDataExists(true);
                setIsEditMode(false); // Exit edit mode after saving
            } else {
                toast.dismiss()
                toast.warn('Unexpected response from the server.', {
                    autoClose: 1500,
                });
            }
        } catch (error) {
            toast.dismiss()
            toast.error('Error saving data.', {
                autoClose: 1500,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <form className="row g-3" onSubmit={handleSubmit}>
            {/* First Row */}
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Home Title</label>
                    <input
                        name="HomeTitle"
                        type="text"
                        className="form-control"
                        value={formValues.HomeTitle}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">About Title</label>
                    <input
                        name="AboutTitle"
                        type="text"
                        className="form-control"
                        value={formValues.AboutTitle}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">OurFleet Title</label>
                    <input
                        name="OurFleetTitle"
                        type="text"
                        className="form-control"
                        value={formValues.OurFleetTitle}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
            </div>

            {/* Second Row */}
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Services Title</label>
                    <input
                        name="ServicesTitle"
                        type="text"
                        className="form-control"
                        value={formValues.ServicesTitle}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Contact Title</label>
                    <input
                        name="ContactTitle"
                        type="text"
                        className="form-control"
                        value={formValues.ContactTitle}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Phone Number</label>
                    <input
                        name="PhoneNumber"
                        type="text"
                        className="form-control"
                        value={formValues.PhoneNumber}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
            </div>

            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Profile Title</label>
                    <input
                        name="ProfileTitle"
                        type="text"
                        className="form-control"
                        value={formValues.ProfileTitle}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">ChangePassword Title</label>
                    <input
                        name="ChangePasswordTitle"
                        type="text"
                        className="form-control"
                        value={formValues.ChangePasswordTitle}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">BookingList Title</label>
                    <input
                        name="BookingListTitle"
                        type="text"
                        className="form-control"
                        value={formValues.BookingListTitle}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Logout</label>
                    <input
                        name="Logout"
                        type="text"
                        className="form-control"
                        value={formValues.Logout}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Login Title</label>
                    <input
                        name="LoginTitle"
                        type="text"
                        className="form-control"
                        value={formValues.LoginTitle}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Register Title</label>
                    <input
                        name="RegisterTitle"
                        type="text"
                        className="form-control"
                        value={formValues.RegisterTitle}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </div>
            </div>

            <div className="card-footer text-end">
                <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loading}
                >
                    {loading ? 'Saving...' : (isDataExists ? 'Update' : 'Add')}
                </button>
            </div>
        </form>
    );
};

export default HeaderMenu;
