import React, { useState } from 'react';
import TableMain from '../../TableComponents/Table/TableMain';
import Header from '../../TableComponents/Header';
import Breadcrumb from '../../TableComponents/Breadcrumb';
import SearchMain from '../../TableComponents/SearchComponents/SearchMain';
import ActiveButton from '../../TableComponents/ActiveButton';
import { useEffect } from 'react';
import ModalMetaTagss from './ModalMetaTags'
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpWideShort, faArrowDownShortWide, faArrowsUpDown } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import axios from 'axios';
import ConfirmationModal from '../../TableComponents/ConfirmationModal';
import { BASE_URL, BACKENDTOKEN } from '../../Constant/Constant'
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

const MetaTags = () => {
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 6;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterTitle, setFilterTitle] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);

  const permissions = useSelector(state => state.roles.permissions.user_modules);
  const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });

  useEffect(() => {
    if (permissions) {
      try {
        const modules = JSON.parse(permissions);
        const MetaTags = modules.find(module => module.rolename === 'MetaTags');
        if (MetaTags) {
          setRolePermissions(MetaTags.permissions);
        }
      } catch (error) {
        console.error('Failed to parse permissions:', error);
      }
    }
  }, [permissions]);


  const navigate = useNavigate();
  const fetchMetaTags = async () => {
    setLoading(true);
    const languageId = localStorage.getItem('selectedLanguageId');
    try {
      const response = await fetch(`${BASE_URL}metatags/get?language_id=${languageId}`, {
        headers: {
          'Authorization': `Bearer ${BACKENDTOKEN}`
        }
      });

      if (!response.ok) {
        throw new Error(`Server error: ${response.status}`);
      }

      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        const result = await response.json();
        if (Array.isArray(result) && result.length > 0) {
          setData(result);
          setFilteredData(result);
          setSelectedRows(Array(result.length).fill(false)); // Initialize selected rows

        } else {
          console.warn('No data found for the given language ID.');
        }
      } else {
        const text = await response.text();
        throw new Error('Unexpected content type: ' + contentType);
      }
    } catch (error) {
      setError('Error fetching legal contents: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {

    fetchMetaTags();
  }, []);





  const handleRowSelect = (index) => {
    setSelectedRows(prevSelectedRows => {
      const updated = [...prevSelectedRows];
      updated[index] = !updated[index]; // Toggle the selected state
      return updated;
    });
  };

  const handlePageChange = (pageNumber) => {
    const totalPages = Math.ceil(data.length / recordsPerPage);
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredData.slice(indexOfFirstRecord, indexOfLastRecord);



  // const updateStatus = async (newStatus) => {
  //     const selectedIds = currentRecords
  //         .map((item, index) => (selectedRows[index] ? item.id : null))
  //         .filter(id => id !== null);

  //     if (selectedIds.length === 0) {
  //         console.warn('No rows selected for status update.');
  //         return; // Exit if no IDs are selected
  //     }

  //     try {
  //         if (rolePermissions.write === 'no') {
  //             toast.dismiss();
  //             toast.error('You do not have permission to update data.', {
  //                 autoClose: 1500,
  //             });
  //             return;
  //         }
  //         const updatePromises = selectedIds.map(async (id) => {
  //             const response = await fetch(`${BASE_URL}MetaTags/update-status/${id}`, {
  //                 method: 'PUT',
  //                 headers: {
  //                     'Authorization': `Bearer ${BACKENDTOKEN}`,
  //                     'Content-Type': 'application/json',
  //                 },
  //                 body: JSON.stringify({ status: newStatus }),
  //             });

  //             if (!response.ok) {
  //                 throw new Error(`Failed to update  with ID ${id}`);
  //             }

  //             // Update local data state to reflect status change
  //             setFilteredData(prevFilteredData =>
  //                 prevFilteredData.map(item => item.id === id ? { ...item, status: newStatus } : item)
  //             );
  //         });

  //         // Wait for all updates to complete
  //         await Promise.all(updatePromises);

  //         // Show success message after all updates
  //         toast.dismiss();
  //         toast.success('Status updated successfully!', {
  //             autoClose: 1500,
  //         });
  //     } catch (error) {
  //         toast.dismiss();
  //         toast.error('Failed to update status.', {
  //             autoClose: 1500,
  //         });
  //     }

  //     // Reset selected rows
  //     setSelectedRows(Array(currentRecords.length).fill(false));

  //     // Optionally reload data to fetch latest updates
  //     // fetchData();
  // };
  const handleRowAction = async (action, id, actionType) => {
    switch (action) {
      case 'view':
        if (rolePermissions.read === 'no') {
          toast.dismiss();
          toast.error('You do not have permission to update data.', {
            autoClose: 1500,
          });
          return;
        }
        navigate(`../action_metatags/${id}?action=view`);
        break;
      case 'edit':
        if (rolePermissions.write === 'no') {
          toast.dismiss();
          toast.error('You do not have permission to update data.', {
            autoClose: 1500,
          });
          return;
        }
        navigate(`../action_metatags/${id}?action=edit`);
        break;
      case 'delete':
        if (rolePermissions.write === 'no') {
          toast.dismiss();
          toast.error('You do not have permission to update data.', {
            autoClose: 1500,
          });
          return;
        }
        setUserIdToDelete(id);
        setIsModalOpen(true);
        break;
      default:
        console.warn(`Unknown action ${action} for item with id ${id}`);
    }
  };
  const handleDeleteUser = async () => {
    try {
      await axios.delete(`${BASE_URL}metatags/delete/${userIdToDelete}`, {
        headers: {
          'Authorization': `Bearer ${BACKENDTOKEN}`
        }
      });
      toast.success('User deleted successfully', {
        autoClose: 1500,
      });
      setFilteredData(prevData => prevData.filter(item => item.id !== userIdToDelete));
    } catch (error) {
      toast.error('Error deleting user: ' + error.message, {
        autoClose: 1500,
      });
    } finally {
      setIsModalOpen(false);
    }
  };
  const openModal = (userId) => {
    setUserIdToDelete(userId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handlePrimaryClick = () => {
    // Filter data based on filterTitle and filterStatus
    const filtered = data.filter(item => {
      return (
        (filterTitle.trim() === '' || item.category.toLowerCase().includes(filterTitle.trim().toLowerCase())) &&
        (filterStatus.trim() === '' || item.status.toLowerCase() === filterStatus.trim().toLowerCase())
      );
    });
    setFilteredData(filtered);
    setCurrentPage(1);
  };

  const handleResetClick = () => {
    setFilterTitle('');
    setFilterStatus('');
    setFilteredData(data);
    setCurrentPage(1);
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sortedData = [...filteredData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'asc' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });

    setFilteredData(sortedData);
  };


  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? (
        <FontAwesomeIcon icon={faArrowUpWideShort} />
      ) : (
        <FontAwesomeIcon icon={faArrowDownShortWide} />
      );
    }
    return <FontAwesomeIcon icon={faArrowsUpDown} />;
  };

  const addLink = rolePermissions.write === 'yes' ? <ModalMetaTagss refreshData={fetchMetaTags} /> : null;
  // Render loading or error message while fetching data
  if (loading) {
    return <div className="text-center">Loading data...</div>;
  }

  // if (error) {
  //   return <div className="text-center text-danger mt-5">{error}</div>;
  // }

  return (
    <div className="container-xl my-4">
      <Helmet>
        <title>MetaTags</title>
        <meta name="description" content="This is the meta tags page of the application" />
      </Helmet>
      <div className='d-flex justify-content-between'>
        <Header title="MetaTags" />
        <Breadcrumb items={[
          { label: "Back", link: "#" },
          { label: "List", link: "#" }
        ]} />
      </div>

      <SearchMain

        statusOptions={[
          { value: "Active", label: "Active" },
          { value: "Inactive", label: "Inactive" }
        ]}
        searcherData={{ namePlaceholder: "Enter Category.." }}
        filterTitle={filterTitle}
        filterStatus={filterStatus}
        handlePrimaryClick={handlePrimaryClick}
        handleResetClick={handleResetClick}
        setFilterTitle={setFilterTitle}
        setFilterStatus={setFilterStatus}
      />

      <TableMain
        title="List"
        addLink={addLink}
        columns={[
          { key: "category", label: "Category" },
          { key: "meta_title", label: "Meta Title" },
          // { key: "meta_description", label: "Meta Description" },
          // { key: "meta_keywords", label: "Meta Keywords" },
        ]}
        data={currentRecords}
        setData={setData}
        selectedRows={selectedRows}
        handleRowSelect={handleRowSelect}
        setSelectedRows={setSelectedRows}
        currentPage={currentPage}
        recordsPerPage={recordsPerPage}
        onPageChange={handlePageChange}
        handleRowAction={handleRowAction}
        filteredData={filteredData}
        totalRecords={filteredData.length}
        handleSort={handleSort}
        getSortIndicator={getSortIndicator}
checkbox="flase"
        canEdit={rolePermissions.write === 'yes'}
      />
      {/* {rolePermissions.write === 'yes' && (
                <ActiveButton
                    onActivate={() => updateStatus('active')}
                    onDeactivate={() => updateStatus('inactive')}
                />
            )} */}
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={handleDeleteUser}
        message="Are you sure you want to delete this data?"
      />
    </div>
  );
};

export default MetaTags;




