// utils/decodeToken.js
import {jwtDecode} from 'jwt-decode';

export const getTokenData = () => {
    const token = localStorage.getItem('BackendToken');
    if (token) {
        try {
            // Decode the token without verifying its signature
            const decodedToken = jwtDecode(token);
            // Extract userId and branchId from the decoded token
            const userId = decodedToken.id || null;
            return { userId};
        } catch (error) {
            console.error('Error decoding token', error);
            return { userId: null};
        }
    }
    return { userId: null};
};
