import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BASE_URL,BACKENDTOKEN} from '../../Constant/Constant'
import { useSelector } from 'react-redux';

const ActionExtra = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { id } = useParams();
  const action = queryParams.get('action');
  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [duration, setDuration] = useState('');
  const [quantity, setQuantity] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [isEditable, setIsEditable] = useState(false);

  const [message, setMessage] = useState(null);
  
const permissions = useSelector(state => state.roles.permissions.user_modules);
const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });

useEffect(() => {
    if (permissions) {
        try {
            const modules = JSON.parse(permissions);
            const extraoption = modules.find(module => module.rolename === 'extraoption');
            if (extraoption) {
                setRolePermissions(extraoption.permissions);
            }
        } catch (error) {
            console.error('Failed to parse permissions:', error);
        }
    }
}, [permissions]);

  useEffect(() => {
    axios.get(`${BASE_URL}extraoption/get/${id}`, {
      headers: {
          'Authorization': `Bearer ${BACKENDTOKEN}`
      }
  })
      .then(response => {
        const data = response.data;
        setTitle(data.title);
        setPrice(data.price);
        setDuration(data.duration);
        setQuantity(data.quantity);
        setDescription(data.description);
        setCategory(data.category)
      })
      .catch(error => {
        toast.error('Error fetching ExtraOptions', {
          autoClose: 1500,             
});
      });
  }, [id]);

  useEffect(() => {
    setIsEditable(action === 'edit');
  }, [action]);

  const handleUpdate = () => {
    if (rolePermissions.write === 'no') {
      toast.dismiss();
      toast.error('You do not have permission to update data.', {
          autoClose: 1500,             
  });
      return;
  }
    axios.put(`${BASE_URL}extraoption/update/${id}`, {title,price,duration,quantity,description, category }, {
      headers: {
          'Authorization': `Bearer ${BACKENDTOKEN}`
      }
  })
      .then(response => {
        toast.success('ExtraOptions updated successfully', {
          autoClose: 1500,             
});
        setTimeout(() => {
          setMessage(null);
          navigate(`/actionextra/${id}?action=view`);
        });
      })
      .catch(error => {
        toast.error('Failed to update  ExtraOptions', {
          autoClose: 1500,             
});
        setTimeout(() => {
          setMessage(null);
        }, 2000);
      });
  };

  const handleEdit = () => {
    navigate(`/actionextra/${id}?action=edit`);
  };

  const handleCancel = () => {
    navigate(`/actionextra/${id}?action=view`);
  };
  const categories = [
    { value: '', label: 'Select Category' },
    { value: 'select', label: 'Select Item' },
    { value: 'quantity', label: 'Quantity Item' }
  ];

  return (
    <div className="container-xl my-4">

      <div className="row">
        <div className="col-md-6">
          <h4>ExtraOptions</h4>
        </div>
        <div className="col-md-6">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-end fs-3">
            <li className="breadcrumb-item"><Link to="../extraoptionslist" className='fs-6'>Back</Link></li>
              <li className="breadcrumb-item"><Link to="#" className='fs-6'>ActionPage</Link></li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="col-12 mt-3">
        <div className="card mt-3">
          <div className="card-header header-underline pb-1">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="card-legal_title">{action === 'edit' ? 'Edit Details' : 'View Details'}</h5>
            </div>
          </div>
          <div className="card-body mt-3">
          <form className="row g-3">
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="col-sm col-form-label">Category</label>
                  <select
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    disabled={!isEditable}
                    className="form-select mb-2"
                  >
                    {categories.map(cat => (
                      <option key={cat.value} value={cat.value}>{cat.label}</option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label className="col-sm col-form-label">Title</label>
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    disabled={!isEditable}
                    className="form-control mb-2"
                  />
                </div>
                <div className="mb-3">
                  <label className="col-sm col-form-label">Quantity</label>
                  <input
                    type="number"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    disabled={!isEditable}
                    className="form-control mb-2"
                  />
                </div>
              </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label className="col-sm col-form-label">Price</label>
              <input
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                disabled={!isEditable}
                className="form-control mb-2"
              />
            </div>
            <div className="mb-3">
              <label className="col-sm col-form-label">Description</label>
              <input
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                disabled={!isEditable}
                className="form-control mb-2"
              />
            </div>
            <div className="mb-3">
              <label className="col-sm col-form-label">Duration</label>
              <input
                type="text"
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                disabled={!isEditable}
                className="form-control mb-2"
              />
            </div>
            </div>
            {isEditable ? (
              <div className="col-12 d-flex justify-content-end align-items-center">
                <button type="button" onClick={handleCancel} className="btn btn-label-secondary me-3">
                  Cancel
                </button>
                <button type="button" onClick={handleUpdate} className="btn btn-primary">
                 Update
                </button>
                
              </div>
            ) : (rolePermissions.write === 'yes' && (
              <div className="col-12 d-flex justify-content-end align-items-center">
                <button type="button" onClick={handleEdit} className="btn btn-primary">
                  Edit
                </button>
              </div>
          )  )}
          </form>

          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionExtra;
